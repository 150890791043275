import regs from '@/utils/regs';
import { callbackify } from 'util';
const rules: any = {
    require: { required: true, message: '请输入必填内容', trigger: 'change' },
    timeRange(edit, params) {
        return {
            trigger: 'change',
            required: true,
            validator(r, v, callback) {
                let [sp, ep] = params,
                    stime = edit[sp],
                    etime = edit[ep];
                if (stime && etime) callback();
                else callback(new Error('请填写时间'));
            }
        };
    },
    couponDiscount(edit, params) {
        return {
            trigger: 'change',
            required: true,
            validator(r, v, callback) {
                let i = edit['discountAmount'] || edit['discountRate'];
                console.log(i, edit);

                if (i) {
                    callback();
                } else callback(new Error('请填写折扣或者折扣金额'));
            }
        };
    },
    mobile8: {
        trigger: 'change',
        required: true,
        validator(r, v, callback) {
            console.log(regs.mobile8.test(v), v);
            if (!regs.mobile8.test(v)) {
                callback(new Error('请填写正确的电话或者座机'));
            } else {
                callback();
            }
        }
    },
    mobile: {
        trigger: 'change',
        required: true,
        validator(r, v, callback) {
            console.log(regs.mobile.test(v), v);
            if (!regs.mobile.test(v)) {
                callback(new Error('请填写正确的电话或者座机'));
            } else {
                callback();
            }
        }
    }
};

export default rules;
