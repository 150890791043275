import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/testOrder/list',
        method: 'get',
        name: 'getTestOrderList'
    },
    {
        url: 'manage/testOrder/add',
        method: 'post',
        name: 'addTestOrder'
    },
    {
        url: 'manage/testOrder/edit',
        method: 'post',
        name: 'editTestOrder'
    },
    {
        url: 'manage/testOrder/orderCheck',
        method: 'post',
        name: 'testOrderCheck'
    },
    {
        url: 'manage/testOrder/createSendSf',
        method: 'get'
    },
    {
        url: 'manage/testOrder/upExport',
        method: 'post'
    },
    {
        url: 'manage/testOrder/replyExpress',
        method: 'post'
    },
    {
        url: 'manage/testOrder/signOrder',
        method: 'post'
    },
    {
        url: 'manage/testOrder/invoice',
        method: 'post'
    },
    {
        url: 'manage/testOrder/getDetails',
        method: 'get',
        name: 'getTestOrderDetails'
    },

    {
        url: 'manage/testOrder/close',
        method: 'post',
        name: 'closeOrder'
    },

    {
        url: 'manage/testOrder/export',
        method: 'get',
        down: true,
        name: 'exportOrderList'
    }
];
const order: Sqi = new Sqi(list);
export default order;
