import request from '@/utils/request';

export const getUserInfo = (data: any) =>
    request({
        url: '/manage/main/user',
        method: 'get',
        data
    });

export const login = (data: any) =>
    request({
        url: '/manage/login',
        method: 'post',
        data
    });

export const logout = () =>
    request({
        url: '/manage/logout',
        method: 'get'
    });
export const getCode = () =>
    request({
        url: '/manage/common/verifyCaptcha',
        method: 'get'
    });
export const getMenuList = () =>
    request({
        url: '/manage/main/menu',
        method: 'get'
    });
export const getUserRole = () =>
    request({
        url: '/manage/main/hasPermissions',
        method: 'get'
    });
