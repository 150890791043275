import EnumLabel from './EnumLabel';
export default EnumLabel;
export const REQUEST = {
    METHOD: {
        GET: 'get',
        POST: 'post',
        DELETE: 'delete'
    },
    RESPONSE: {
        STATUS: {
            SUCCESS: 200,
            NOT_LOGIN: 401
        }
    }
};
export const DEFAULT_SIZE = window.outerWidth > 1920 ? 19 : 13;
export enum FORM_ITEM_TYPE {
    SHOW = 'show',
    TEXT = 'text',
    TEXTAREA = 'textarea',
    CHANNEL = 'channel',
    SELECT = 'select',
    DATE = 'date',
    BRAND = 'brand',
    UPLOAD_IMG = 'upload-img',
    UPLOAD = 'upload',
    DATE_RANGE = 'daterange',
    DATE_RANGE_DOUBLE = 'daterange-double',
    VIP_ROOM = 'vip-room',
    VIP_CARD = 'vip-card',
    HIDDEN = 'hidden',
    CHECKBOX = 'checkbox',
    SELECT_SHOP = 'select-shop',
    BRAND_VIP = 'brand-vip',
    BRAND_COUPON = 'brand-coupon',
    RICH_TEXT = 'rich-text',
    TIME_RANGE = 'time-range',
    ORG_TREE = 'org-tree',
    SELECT_CITY = 'select-city',
    DATE_TIME_LIST = 'date-time-list',
    EMPTY = 'empty',
    NUM_RANGE = 'num-range',
    NUMBER = 'number',
    INPUTS = 'inputs',
    RADIO = 'radio',
    MARKDOWN = 'markdown',
    UPLOAD_IMG_LIST = 'upload-img-list',
    CASCADER = 'cascader',
    TIME = 'time'
}

// export const CONTRACT_STATUS: EnumLabel = new EnumLabel([
//     {
//         label: '启用',
//         prop: 'on',
//         value: 1
//     },
//     {
//         label: '停用',
//         prop: ' off',
//         value: 2
//     }
// ]);
