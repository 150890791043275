export interface EnumItem {
    label?: string;
    prop?: string;
    value?: string | number | boolean | null;
}
class EnumLabel {
    org: Array<EnumItem> = [];
    constructor(list: Array<EnumItem>) {
        console.log('-------2--2-------', list);
        this.org = list;
    }
    public static serverToEnum(obj: any) {
        let opt: any = Object.keys(obj).map((i) => ({ label: obj[i], value: +i }));
        return new EnumLabel(opt);
    }

    public static serverToEnumOrg(obj: any) {
        let opt: any = Object.keys(obj).map((i) => ({ label: obj[i], value: i }));
        return new EnumLabel(opt);
    }

    public static serverToEnumChange(obj: any) {
        let opt: any = Object.keys(obj).map((i) => ({ label: obj[i], value: i }));
        return new EnumLabel(opt);
    }
    _getRes(key: string, val_key: string) {
        let _enum: any = {},
            last_val: number;
        this.org.forEach((i: any) => {
            _enum[i[key]] = i[val_key] ?? last_val;
            last_val += 1;
        });
        return _enum;
    }
    get VALUE_LABEL() {
        return this._getRes('value', 'label');
    }
    get PROP_LABEL() {
        return this._getRes('prop', 'label');
    }
    get PROP_VALUE() {
        return this._getRes('prop', 'value');
    }
    get VALUE_PROP() {
        return this._getRes('value', 'prop');
    }
}

export default EnumLabel;
