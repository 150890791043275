import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/point/particulars/list',
        method: 'post',
        name: 'getPointManageList'
    },
    {
        url: 'manage/point/particulars/checkBack',
        method: 'post',
        name: 'backPoint'
    },

    {
        url: 'manage/point/manage/getAction',
        method: 'get'
    },

    {
        url: 'manage/pointGoods/record/export',
        method: 'get',
        down: true,
        name: 'exportPointGoodsRecord'
    },
    {
        url: 'manage/point/particulars/export',
        method: 'get',
        down: true,
        name: 'exportPoint'
    }
];
const point: Sqi = new Sqi(list);
export default point;
