<template>
    <div class="navbar">
        <hamburger
            id="hamburger-container"
            :is-active="sidebar.opened"
            class="hamburger-container"
            @toggle-click="toggleSideBar"
        />
        <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { AppModule } from '@/store/modules/app';
    import { UserModule } from '@/store/modules/user';
    import Breadcrumb from '@/components/Breadcrumb/index.vue';
    import Hamburger from '@/components/Hamburger/index.vue';

    @Component({
        name: 'Navbar',
        components: {
            Breadcrumb,
            Hamburger
        }
    })
    export default class extends Vue {
        get sidebar() {
            return AppModule.sidebar;
        }

        get device() {
            return AppModule.device.toString();
        }

        get avatar() {
            return UserModule.avatar;
        }

        private toggleSideBar() {
            AppModule.ToggleSideBar(false);
        }

        private async logout() {
            await UserModule.LogOut();
            this.$router.push(`/login?redirect=${this.$route.fullPath}`);
        }
    }
</script>

<style lang="scss" scoped>
    .navbar {
        height: $sideBarHeight;
        overflow: hidden;
        position: relative;
        background: #fff;
        box-shadow: 10px 2px 12px 0 rgba(0, 0, 0, 0.15);

        .hamburger-container {
            line-height: 46px;
            height: 100%;
            float: left;
            padding: 0 15px;
            cursor: pointer;
            transition: background 0.3s;
            -webkit-tap-highlight-color: transparent;

            &:hover {
                background: rgba(0, 0, 0, 0.025);
            }
        }

        .breadcrumb-container {
            float: left;
        }
    }
</style>
