import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/employee/recommend/add',
        method: 'post',
        name: 'addRecommender'
    },
    {
        url: 'manage/employee/manage/list',
        method: 'post',
        name: 'getRecommenderList'
    },
    {
        url: 'manage/employee/recommend/getOaUser',
        method: 'get'
    },
    {
        url: '/manage/employee/recommend/list',
        method: 'post',
        name: 'getRecommendOrder'
    },
    {
        url: '/manage/employee/recommend/getPaySumAmount',
        method: 'post',
        name: 'getRecommendPayTotal'
    },
    {
        url: '/manage/employee/recommend/getOpenCardCount',
        method: 'post',
        name: 'getRecommendVipTotal'
    },
    {
        url: '/manage/employee/manage/createRecommenderQRCode',
        method: 'post'
    },
    {
        url: '/manage/employee/manage/createRecommenderQRCodeList',
        method: 'post'
    },
    {
        url: '/manage/employee/recommend/export',
        down: true,
        method: 'get',
        name: 'exportEmployeeList'
    }
];

const recommender: Sqi = new Sqi(list);
export default recommender;
