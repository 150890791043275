<template>
    <div
        class="view-form-container"
        :class="inline && 'el-form--inline'"
        v-loading="disabled"
        element-loading-background="rgba(255,255,255,.6)"
        element-loading-spinner="el-icon-warning-outline"
        element-loading-text="禁止编辑"
        element-loading-custom-class="loading-mask"
    >
        <el-form
            :label-position="labelPosition"
            :label-width="isSearch ? '150px' : labelWidth"
            v-for="(itemVal, k) of valList"
            :key="k"
            :class="[
                {
                    'double-row': isDoubleRow,
                    'is-oper': formList.length > 4,
                    'is-search': isSearch,
                    'is-close': !searchOpenStatus
                },
                formClass
            ]"
            :inline="inline"
            :rules="rules"
            ref="form"
            :validate-on-rule-change="false"
            :model="itemVal.edit"
            :style="{ height: isSearch ? Math.ceil(formList.length / 4) * 62 + 'px' : 'auto' }"
        >
            <i
                :class="searchOpenStatus ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                @click="searchOpenStatus = !searchOpenStatus"
                v-if="isSearch && formList.length > 4"
            ></i>
            <!-- <slot name="subtit" :edit="itemVal.edit" /> -->

            <div class="el-form-sub-tit" v-if="subTit">
                <template v-if="subTit">{{ subTit }}</template>
            </div>
            <slot name="subtit" :edit="itemVal.edit" v-else />
            <template v-for="(i, k) of formList">
                <div
                    class="el-form-sub-tit"
                    v-if="i.subTit || $slots[i.subTitSite]"
                    :key="k + 'sub-tit'"
                >
                    <template v-if="i.subTit">{{ i.subTit }}</template>
                    <slot :name="i.subTitSite" :edit="itemVal.edit" v-else />
                </div>
                <el-form-item
                    :key="k"
                    v-if="
                        !(
                            i.type === FORM_ITEM_TYPE.HIDDEN ||
                            (i.isHidden && i.isHidden(itemVal.edit))
                        )
                    "
                    :label="i.label"
                    :prop="i.ruleProp || i.prop"
                    :class="i.type === FORM_ITEM_TYPE.TEXTAREA && 'form-item-textarea'"
                    :style="getWidth(i)"
                >
                    <view-form-value :edit="itemVal.edit" :config="i">
                        <template #default>
                            <slot :name="i.site" :edit="itemVal.edit" />
                        </template>
                    </view-form-value>
                </el-form-item>
            </template>
            <i class="el-icon-delete" v-if="min < k + 1" @click="delItem(k)"></i>
        </el-form>
        <v-button class="font-finish" type="oper" :is-icon="false" @click.native="valAdd"
            ><i class="el-icon-circle-plus-outline"></i>新增</v-button
        >
    </div>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        PropSync,
        Watch,
        Emit,
        ModelSync
    } from 'vue-property-decorator';
    import FormStructure, { viewFormConfig } from '@/utils/FormStructure';
    import userManage from '@/api/userManage';
    import SqiExtend from '@/api';
    import Time from '@/utils/Time';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import ViewFormValue from './item.vue';
    import rules from './rules';
    import ViewButton from '../ViewButton';
    @Component({
        name: 'ViewFormList',
        components: {
            ViewFormValue: ViewFormValue,
            VButton: ViewButton
        }
    })
    export default class ViewForm extends SqiExtend {
        @ModelSync('value', 'change') valList!: FormStructure[];
        @PropSync('del') delArr!: any[];
        @Prop()
        formList!: viewFormConfig[];
        @Prop() init: any;
        @Prop({ default: false, type: Boolean }) isDoubleRow!: boolean;
        @Prop({ type: Function }) dataHandle?: any;
        @Prop({ default: '100px' }) labelWidth!: any;
        @Prop({ default: false }) inline!: boolean;
        @Prop({ default: 'right' }) labelPosition!: string;
        @Prop({ default: false, type: Boolean }) isFormInit!: boolean;
        @Prop({ default: true, type: Boolean }) isInit!: boolean;
        @Prop({ default: false, type: Boolean }) isReload!: boolean;
        @Prop({ default: '', type: String }) subTit!: string;
        @Prop({ default: '50%', type: String }) itemWidth!: string;
        @Prop({ default: false, type: Boolean }) isDelData!: boolean;
        @Prop({ default: false, type: Boolean }) isSearch!: boolean;
        @Prop({ default: '', type: String }) formClass!: string;
        @Prop({ default: false, type: Boolean }) notRequired!: boolean;
        @Prop({ default: 0, type: Number }) min!: number;
        @Prop({ default: false, type: Boolean }) disabled?: number;

        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        edit: any = {};
        form: any = {};
        widt100Type = [
            FORM_ITEM_TYPE.TEXTAREA,
            FORM_ITEM_TYPE.CHECKBOX,
            FORM_ITEM_TYPE.RICH_TEXT,
            FORM_ITEM_TYPE.DATE_TIME_LIST
        ];
        // get form() {
        // 	return new FormStructure(this.formList.filter((i: any) => i.prop));
        // }
        // get edit() {
        // 	return this.form.edit;
        // }

        // valList: any = [];

        getWidth(i) {
            return this.inline
                ? {}
                : {
                      width: i.width
                          ? i.width
                          : this.widt100Type.includes(i.type)
                          ? '90%'
                          : this.itemWidth
                  };
        }

        get rules() {
            const { formList, edit, isSearch, notRequired } = this;
            return Object.assign(
                {},
                ...formList
                    .filter((i) => i.rules || i.prop || i.ruleProp)
                    .map((i) => ({
                        [i.prop || i.ruleProp]: i.rules
                            ? i.rules.map((j) => {
                                  if (typeof j == 'string') {
                                      return rules[j];
                                  } else {
                                      if (typeof j.rule === 'string') {
                                          return rules[j.rule](edit, j.params);
                                      } else if (typeof j.rule === 'function') {
                                          return j.rule(edit, j.params);
                                      } else {
                                          console.error('未找到改规则');
                                      }
                                  }
                              })
                            : [
                                  isSearch || i.notRequire || notRequired
                                      ? { required: false }
                                      : rules.require
                              ]
                    }))
            );
        }

        @Watch('valList.length')
        @Watch('valList')
        async clearVal() {
            await this.$nextTick((v) => {
                this.$refs?.form?.map((i, k) => {
                    i?.clearValidate();
                    this.valList[k].setRef?.(i);
                });
            });
        }

        get key() {
            return Date.now() + '-form';
        }

        searchOpenStatus: boolean = false;

        get formProps() {
            return this.formList.map((i: any) => i.props || [i.prop]).flat(2);
        }

        @Watch('formProps', { immediate: true })
        judgeProps(n: (string | undefined)[], o: (string | undefined)[]) {
            console.log('create time', n);
            if (n?.sort().join('') !== o?.sort().join('') && n.length) {
                this.initForm();
            }
        }

        @Watch('init')
        initForm() {
            const { init } = this;
            if (init && init.length) {
                this.valList = this.init.map((i) => this.getForm().setData(i));
            } else {
                if (this.isFormInit) {
                    this.valList = [];
                } else {
                    this.valList = [this.getForm()];
                }
            }
        }

        getForm() {
            let form = new FormStructure(FormStructure.viewFormConfigToFormConfig(this.formList));

            return form;
        }
        valAdd() {
            this.valList.push(this.getForm());
        }
        delItem(k: number) {
            let del: any = this.valList.splice(k, 1)[0] || {};
            del.edit?.id && this.delArr.push(del.edit.id);
        }
        beforeDestroy() {}

        created() {}
    }
</script>
<style lang="scss">
    .el-form-sub-tit {
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
        margin-bottom: 20px;
    }
</style>
<style lang="scss" scoped>
    .double-row {
        @include flex-layout(flex-start);
        flex-wrap: wrap;
        .el-form-item {
            width: 45%;
            flex-shrink: 0;
        }

        .form-item-textarea {
            width: 100%;
        }
    }

    .view-form-container {
        &::v-deep .el-loading-mask {
            font-size: 40px;
        }
        &::v-deep .loading-mask {
            z-index: 1000 !important;
        }
    }

    .is-search {
        &.is-oper {
            padding-right: 50px;
            position: relative;
            transition: all 0.3s;
            overflow-y: hidden;
            width: 100% !important;
            flex-shrink: 0;
            flex-grow: 1;
        }
        &.is-close {
            height: 62px !important;
        }
        & > i {
            position: absolute;
            font-size: 30px;
            right: 0;
            top: 5px;
            color: #aaa;
            cursor: pointer;
        }
        .el-form-item {
            width: calc(25% - 10px);
            min-width: 396px;
        }
    }
    .view-form-container {
        position: relative;
        padding-bottom: 20px;
        .el-form {
            position: relative;
            padding-right: 60px;
        }
        i[class^='el-icon-delete'] {
            flex-shrink: 0;
            font-size: 20px;
            color: #f56c6c;
            margin-left: 10px;
            line-height: 30px;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
        }
        button {
            text-align: right;
            position: absolute;
            bottom: 0;
            right: 10px;
        }
    }
    .vf-sub {
        width: 100%;
        text-align: center;
    }
    .vf-head {
        width: 100%;
        height: 50px;
        @include flex-layout;
        color: #343434;
        font-weight: bold;
        font-size: 18px;
    }
</style>
