<template>
    <div class="table-main">
        <el-table
            ref="table"
            :key="comKey"
            :data="data"
            row-class-name="table-padding-left"
            :border="tableConfig.border || false"
            stripe
            header-cell-class-name="vt-header"
            @select="$emit('select', $event)"
            @select-all="$emit('select-all', $event)"
            @select-change="$emit('select-change', $event)"
            v-bind="tableConfig"
        >
            <el-table-column
                v-for="(i, k) of rowConfigAppendBrand"
                show-overflow-tooltip
                :key="k"
                align="center"
                v-bind="i"
            >
                <template v-if="i.chirden">
                    <el-table-column v-for="(j, index) of i.chirden" v-bind="j" :key="index">
                        <template v-if="!j.site && j.prop" #default="{ row }">
                            <template v-if="j.copy">
                                <span @click="copyText(getTableProp(row, j))">
                                    {{ getTableProp(row, j) }}
                                </span>
                            </template>
                            <template v-else-if="j.switch">
                                <el-switch :value="row[j.prop]" disabled> </el-switch>
                            </template>

                            <template v-else>
                                {{ getTableProp(row, j) }}
                            </template>
                        </template>
                    </el-table-column>
                </template>
                <template #header="slot">
                    <slot name="header" v-bind="slot">
                        {{ slot.column.label }}
                    </slot>
                </template>

                <template v-if="!i.site && i.prop" #default="{ row }">
                    <template v-if="i.copy">
                        <span @click="copyText(getTableProp(row, i))">
                            {{ getTableProp(row, i) }}
                        </span>
                    </template>
                    <template v-else-if="i.switch">
                        <el-switch :value="row[i.prop]" disabled> </el-switch>
                    </template>
                    <template
                        v-else-if="
                            i.isStatus ||
                            (['channel', 'brandId', 'invoiceStatus', 'brandName'].includes(
                                i.prop
                            ) &&
                                !i.ignore)
                        "
                    >
                        <el-tag :type="i.sType || getFontColor(row, i)">
                            {{ getTableProp(row, i) }}
                        </el-tag>
                    </template>
                    <template v-else>
                        {{ getTableProp(row, i) }}
                    </template>
                </template>
                <!-- <template v-else-if="i.isStatus" #default="{row}">
                    <span><slot :name="i.site" :row="row" /></span>    
                </template> -->

                <template v-else-if="i.site" #default="{ row, $index }">
                    <slot :name="i.site" :row="row" :index="$index" />
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <template v-if="showPage">
            <view-pagination
                :v-if="isPage"
                :total="total"
                :hide-on-single-page="!isPage"
                :current-page.sync="Page"
                :page-size.sync="Size"
            >
                <slot name="page" />
            </view-pagination>
        </template>
    </div>
</template>
<script lang="ts">
    import { Component, Inject, Model, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
    import regs from '@/utils/regs';
    import ViewPagination from '../ViewPagination';
    import filters from '@/utils/filters';
    import SqiExtend from '@/api';
    import utils from '@/utils';
    import ServerEnum from '@/store/modules/global';
    import EnumLabel from '@/constant/EnumLabel';
    @Component({
        name: 'ViewTable',
        components: {
            ViewPagination
        }
    })
    export default class MainTable extends SqiExtend {
        @Prop(Number) total!: number;
        @Prop({
            type: Array,
            default: function () {
                return {
                    // table row item attr
                    /*	row:[
        																																																													{
        																																																														label:"",
        																																																														prop:"",
        																																																														site:"",//"slot位置参数"
        																																																														filter:"",//数据过滤器名称
        																																																													}
        																																																												] */
                };
            }
        })
        rowConfig: any;

        @Prop({
            type: Object,
            default: function () {
                return {
                    // table attr
                };
            }
        })
        tableConfig: any;

        get rowConfigAppendBrand() {
            let config = [...this.rowConfig];

            return config;
        }

        @Prop([Array]) data: any;

        @Prop({ type: Boolean, default: true })
        isPage!: boolean;
        @Prop({ type: Boolean, default: true })
        showPage!: boolean;
        @Prop(Number)
        currentPage!: number;
        get Page() {
            let search = this.$route.query.search ?? '',
                page = search ? JSON.parse(search) : '';

            return this.currentPage ?? this.$route;
        }

        set Page(v) {
            this.$emit('update:currentPage', v);
        }

        @Prop(Number)
        pageSize!: number;
        get Size() {
            return this.pageSize;
        }

        set Size(v) {
            this.$emit('update:pageSize', v);
        }

        toggleRowSelection(r, e) {
            this.$refs.table.toggleRowSelection(r, e);
        }
        clearSelection() {
            this.$refs.table.clearSelection();
        }

        comKey: any = Date.now();
        @Watch('rowConfig')
        reload() {
            this.comKey = Date.now();
        }
        copyText(msg) {
            utils.copyText(msg);
        }
        getTableProp(item, config) {
            const df = '--';
            if (config.filter) {
                if (typeof config.filter === 'string') {
                    return filters[config.filter](item[config.prop], item) ?? df;
                } else {
                    return config.filter(item[config.prop], item, filters) ?? df;
                }
            } else {
                if (regs.dateTime.test(item[config.prop])) {
                    return item[config.prop].split(' ')[0] ?? df;
                } else {
                    return item[config.prop] ?? df;
                }
            }
        }
        getFontColor(row, i) {
            let font = this.getTableProp(row, i);
            switch (font) {
                case '待处理':
                case '未开始':
                case '退单':
                case '已开':
                case '金色印象':
                    return 'warning';

                case '进行中':
                case '处理中':
                case '未使用':
                case '管理端':
                    return '';

                case '已完结':
                case '可用':
                case '已完成':
                case '已使用':
                case '小程序':
                case '正常':
                case '是':
                case '在职':
                case '金色春天':
                    return 'success';
                case '不可用':
                case '离职':
                case '金色湖畔':
                case '已封禁':
                case '否':
                    return 'danger';

                case '已处理':
                case '已结束':
                case '取消':
                case '线下':
                case '未开':
                case '康多巴巴':
                    return 'info';
                default:
                    return '';
            }
        }
        get hasPage() {
            return this.$children?.[0].constructor.name ?? {};
        }
        created() {
            console.log(this);
        }
    }
</script>
<style lang="scss" scoped>
    .table-main {
        /* padding:0 20px; */
        border: 1px solid #e4e4e4;
        .el-table::v-deep .vt-header {
            background-color: #f5f7fb;
        }
    }
    .font-blue {
        color: #409eff;
    }
    .font-success {
        color: #67c23a;
    }
</style>
