import Vue from 'vue';
import Router, { RawLocation } from 'vue-router';
import Layout from '@/layout/index.vue';
import NullVue from '@/layout/Null.vue';
import menuList from './menu';
class RouterRewrite extends Router {
    push(location: RawLocation) {
        console.log(location, this.routeMatch);
        return super
            .push(location)
            .then((r) => {})
            .catch((e) => {
                console.error(e);
            });
    }
    tabJump(params) {
        if (['number', 'string'].includes(typeof params)) {
            params = { tab: params };
        }
        let query = Object.assign({}, this.currentRoute.query, params);
        this.replace({ query });
    }
    private menuRoutes: any = [];
    getMenuRoutes() {
        return this.menuRoutes;
    }

    addRoute(route) {
        this.menuRoutes.push(route);
        super.addRoute(route);
    }
    getRoute(name) {
        const routes = this.getMenuRoutes();
    }
    get routeMatch() {
        const menuRoutes = this.getMenuRoutes(),
            route = this.currentRoute,
            matched = route.matched,
            root = menuRoutes.filter((i) => i.name === matched[0].name)[0],
            match = [matched[0]];
        if (route.meta.parent) {
            match.push(root.children.filter((i) => i.name === route.meta.parent)[0]);
        }
        match.push(matched[1]);
        return match;
    }
}

Vue.use(RouterRewrite);

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/
const router: RouterRewrite = new RouterRewrite({
    // mode: 'history',  // Enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
            meta: {
                hidden: true,
                title: '登录'
            }
        },

        {
            path: '/404',
            component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
            meta: {
                hidden: true,
                title: '404'
            }
        }
    ]
});

let getList: any = [];
function getMenuItem({ url, children, menu }: any) {
    for (let i of menuList) {
        if (!getList.includes(url) && i.name === url) {
            let res: any = i;
            res.meta?.title && (res.meta.title = menu);
            getList.push(i.name);

            if (children && children.length) {
                res.children = [];
                for (let j of children) {
                    let kid = getMenuItem(j);
                    kid && res.children.push(kid);
                }
            }
            return res;
        }
    }
    return false;
}
export function addRoutes() {
    try {
        const roles = [
            {
                children: [
                    {
                        id: 3010005,
                        menu: '首页',
                        url: 'Home',
                        parentId: 1
                    }
                ],
                id: 1,
                menu: '首页',
                url: 'Index',
                parentId: null
            },
            {
                children: [
                    {
                        id: 301,
                        menu: '用户管理',
                        url: 'MemberMange',
                        parentId: 300
                    }
                ],
                id: 300,
                menu: '用户管理',
                url: 'Member',
                parentId: null
            },

            {
                children: [
                    {
                        id: 401,
                        menu: '合同管理',
                        url: 'ContractMange',
                        parentId: 400
                    }
                ],
                id: 400,
                menu: '合同管理',
                url: 'Contract',
                parentId: null
            },

            {
                children: [
                    {
                        id: 501,
                        menu: '检测商品管理',
                        url: 'TestShopMange',
                        parentId: 500
                    }
                ],
                id: 500,
                menu: '检测商品管理',
                url: 'TestShop',
                parentId: null
            },

            {
                children: [
                    {
                        id: 601,
                        menu: '检测订单管理',
                        url: 'TestOrderMange',
                        parentId: 600
                    }
                ],
                id: 600,
                menu: '检测订单管理',
                url: 'TestOrder',
                parentId: null
            },
            {
                children: [
                    {
                        id: 701,
                        menu: '页面管理',
                        url: 'PageMange',
                        parentId: 500
                    }
                ],
                id: 700,
                menu: '页面管理',
                url: 'Page',
                parentId: null
            },
            {
                children: [
                    {
                        id: 801,
                        menu: '系统配置',
                        url: 'ConfigMange',
                        parentId: 800
                    }
                ],
                id: 800,
                menu: '系统配置',
                url: 'Config',
                parentId: null
            }
        ];
        let routes = roles.map((i) => getMenuItem(i)).filter((i) => !!i);

        let edits = menuList.filter((i) => i.meta?.hidden);
        routes[0].children.push(...edits);

        routes.forEach((i) => {
            router.addRoute(i);
        });
        // router.addRoute({
        //     path: '*',
        //     redirect: '/404',
        //     meta: { hidden: true }
        // });
    } catch (e) {
        console.log(e);
    }
}

export default router;
