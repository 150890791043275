import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/subject-classify/manage/list',
        method: 'get',
        name: 'getSubjectClassifyList'
    },
    {
        url: 'manage/subject-classify/manage/add',
        method: 'post',
        name: 'addSubjectClassify'
    },
    {
        url: 'manage/subject-classify/manage/update',
        method: 'post',
        name: 'editSubjectClassify'
    },
    {
        url: 'manage/subject-classify/manage/getSubjectClassify',
        method: 'get',
        name: 'getSubjectClassifyDict'
    },
    {
        url: '/manage/subject/manage/add',
        method: 'post',
        name: 'addSubjectInfo'
    },
    {
        url: '/manage/subject/manage/detail',
        method: 'get',
        name: 'getSubjectInfo'
    },
    {
        url: '/manage/subject/manage/list',
        method: 'get',
        name: 'getSubjectInfoList'
    },
    {
        url: '/manage/subject/manage/update',
        method: 'post',
        name: 'editSubjectInfo'
    },
    {
        url: '/manage/subject-tag/manage/add',
        method: 'post',
        name: 'addSubjectTag'
    },
    {
        url: '/manage/subject-tag/manage/list',
        method: 'get',
        name: 'getSubjectTagList'
    },
    {
        url: '/manage/subject-tag/manage/update',
        method: 'post',
        name: 'editSubjectTag'
    },
    {
        url: '/manage/subject-tag/manage/list-all',
        method: 'get',
        name: 'getSubjectTagDict'
    }
];

const subject: Sqi = new Sqi(list);
export default subject;
