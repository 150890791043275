import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/debitCard/manage/list',
        method: 'get',
        name: 'getRechargeUserList'
    },
    {
        url: 'manage/debitCard/manage/delete',
        method: 'post',
        name: 'delDebitCard'
    },
    {
        url: 'manage/debitCard/manage/orderList',
        method: 'get',
        name: 'getRechargeOrderList'
    },
    {
        url: 'manage/debitCard/manage/receiveEntityCard',
        method: 'post',
        name: 'bindRechargeCard'
    },
    {
        url: 'manage/debitCard/activity/add',
        method: 'post',
        name: 'addRechargeActivity'
    },
    {
        url: 'manage/debitCard/activity/update',
        method: 'post',
        name: 'updateRechargeActivity'
    },
    {
        url: 'manage/debitCard/activity/queryDepositPromotions',
        method: 'get',
        name: 'getRechargeRule'
    },
    {
        url: 'manage/debitCard/activity/orderList',
        method: 'get',
        name: 'getRechargeActivityOrder'
    },
    {
        url: 'manage/debitCard/activity/list',
        method: 'get',
        name: 'getRechargeActivityList'
    },
    {
        url: 'manage/debitCard/activity/detail',
        method: 'get',
        name: 'getRechargeActivityDetail'
    }
];

const recharge: Sqi = new Sqi(list);
export default recharge;
