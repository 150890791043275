import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: '/manage/evaluationStimulate/options/list',
        method: 'get',
        name: 'getEvaluateSetting'
    },
    {
        url: '/manage/evaluationStimulate/options/participation/updateStatus',
        method: 'post',
        name: 'setEvaluateTakeStatus'
    },
    {
        url: '/manage/evaluationStimulate/options/participation/save',
        method: 'post',
        name: 'editEvaluateTake'
    },
    {
        url: '/manage/evaluationStimulate/options/evaluateCoupon/save',
        method: 'post',
        name: 'editEvaluateCoupon'
    },
    {
        url: '/manage/evaluationStimulate/options/evaluateCoupon/updateStatus',
        method: 'post',
        name: 'editEvaluateCouponStatus'
    },
    {
        url: '/manage/evaluationStimulate/options/evaluatePoint/updateStatus',
        method: 'post',
        name: 'editEvaluatePointStatus'
    },
    {
        url: '/manage/evaluationStimulate/options/evaluatePoint/save',
        method: 'post',
        name: 'editEvaluatePoint'
    },
    {
        url: '/manage/evaluation/autoClassification/list',
        method: 'get',
        name: 'getAutoBadList'
    },
    {
        url: '/manage/evaluation/autoClassification/evaluateAutoWord/updateStatus',
        method: 'post',
        name: 'setAutoBadStatus'
    },
    {
        url: '/manage/evaluation/autoClassification/evaluateAutoWord/save',
        method: 'post',
        name: 'editAutoBadByFont'
    },
    {
        url: '/manage/evaluation/autoClassification/evaluateAutoRating/save',
        method: 'post',
        name: 'editAutoBadByStar'
    },
    {
        url: '/manage/evaluation/manage/list',
        method: 'post',
        name: 'getEvaluateList'
    },
    {
        url: '/manage/evaluation/manage/getEvaluateInfo',
        method: 'get',
        name: 'getEvaluate'
    },
    {
        url: '/manage/evaluation/manage/reply',
        method: 'post',
        name: 'replyEvaluate'
    }
];

const evaluate: Sqi = new Sqi(list);
export default evaluate;
