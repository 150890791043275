import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: '/manage/manage/shop/list',
        method: 'get',
        name: 'getShopManageList'
    },
    {
        url: '/manage/manage/shop/update',
        method: 'post',
        name: 'updateShop'
    },
    {
        url: '/manage/manage/region/list',
        method: 'get',
        name: 'getRegionList'
    },
    {
        url: '/manage/manage/region/update',
        method: 'post',
        name: 'updateRegion'
    },
    {
        url: '/manage/manage/log/list',
        method: 'get',
        name: 'getLogs'
    },
    {
        url: '/manage/manage/log/detail',
        method: 'get',
        name: 'getLogDetails'
    },
    {
        url: '/manage/manage/user/add',
        method: 'post',
        name: 'addManageUser'
    },
    {
        url: '/manage/manage/user/delete',
        method: 'post',
        name: 'deleteManageUser'
    },
    {
        url: '/manage/manage/user/update',
        method: 'post',
        name: 'updateManageUser'
    },
    {
        url: '/manage/main/resetPassword',
        method: 'post',
        name: 'userResetPassword'
    },
    {
        url: '/manage/manage/user/resetPassword',
        method: 'post',
        name: 'resttingUsserPass'
    },
    {
        url: '/manage/manage/user/list',
        method: 'get',
        name: 'getUserManageList'
    },
    {
        url: '/manage/manage/role/add',
        method: 'post',
        name: 'addPower'
    },
    {
        url: '/manage/manage/role/delete',
        method: 'post',
        name: 'deletePower'
    },
    {
        url: '/manage/manage/role/update',
        method: 'post',
        name: 'updatePower'
    },
    {
        url: '/manage/common/permissions',
        method: 'get',
        name: 'rolePermissions'
    },
    {
        url: '/manage/manage/role/list',
        method: 'get',
        name: 'getPowerList'
    },
    {
        url: '/manage/manage/shop/update',
        method: 'post',
        name: 'updateShop'
    },
    {
        url: '/manage/manage/shop/list',
        method: 'get',
        name: 'getManageShopList'
    },

    {
        url: '/manage/manage/subject/subjectPriceAdd',
        method: 'post',
        name: 'addSubjectPrice'
    },
    {
        url: '/manage/manage/subject/subjectPriceDelete',
        method: 'post',
        name: 'delSubjectPrice'
    },
    {
        url: '/manage/manage/subject/subjectPriceList',
        method: 'get',
        name: 'getSubjectPriceList'
    },
    {
        url: '/manage/manage/subject/subjectPriceUpdate',
        method: 'post',
        name: 'editSubjectPrice'
    },

    {
        url: '/manage/manage/subject/add',
        method: 'post',
        name: 'addSubject'
    },

    {
        url: '/manage/manage/subject/deleteShopSubject',
        method: 'post',
        name: 'deleteShopSubject'
    },
    {
        url: '/manage/manage/subject/detail',
        method: 'get',
        name: 'getSubject'
    },
    {
        url: '/manage/manage/subject/delete',
        method: 'post',
        name: 'delSubject'
    },
    {
        url: '/manage/manage/subject/getShopSubject',
        method: 'get'
    },
    {
        url: '/manage/manage/subject/list',
        method: 'get',
        name: 'getSubjectList'
    },
    {
        url: '/manage/manage/subject/tree',
        method: 'get',
        name: 'getSubjectTree'
    },
    {
        url: '/manage/manage/subject/queryShopSubjects',
        method: 'get',
        name: 'getShopSubjectList'
    },
    {
        url: '/manage/manage/subject/update',
        method: 'post',
        name: 'updateSubject'
    },
    {
        url: '/manage/manage/subject/updateShopSubject',
        method: 'post'
    },
    {
        url: '/manage/manage/meituan/list',
        method: 'get',
        name: 'getMeituanList'
    },
    {
        url: '/manage/manage/meituan/authURL',
        method: 'get',
        name: 'getMeituanAuthUrl'
    },
    {
        url: '/manage/manage/meituan/refresh',
        method: 'post',
        name: 'refreshMeituanAuth'
    },

    {
        url: '/manage/manage/taobao/list',
        method: 'get',
        name: 'getTaobaoList'
    },
    {
        url: '/manage/manage/taobao/authURL',
        method: 'get',
        name: 'getTaobaoAuthUrl'
    },
    {
        url: '/manage/manage/taobao/saveAuth',
        method: 'post',
        name: 'saveTaobaoAuth'
    },

    {
        url: '/manage/manage/advertisement/list',
        method: 'post',
        name: 'getAdverList'
    },
    {
        url: '/manage/manage/advertisement/delete',
        method: 'post',
        name: 'delAdver'
    },
    {
        url: '/manage/manage/advertisement/update',
        method: 'post',
        name: 'editAdver'
    },
    {
        url: '/manage/manage/advertisement/add',
        method: 'post',
        name: 'addAdver'
    },
    {
        url: '/manage/manage/advertisement/getShops',
        method: 'get',
        name: 'getAdverShops'
    },
    {
        url: '/manage/manage/advertisement/details',
        method: 'get',
        name: 'getAdverDetails'
    },
    {
        url: '/manage/manage/shop-account/refreshMeituanShopId',
        method: 'post'
    },
    {
        url: '/manage/manage/pay/add',
        name: 'managePayAdd',
        method: 'post'
    },
    {
        url: '/manage/manage/pay/list',
        name: 'managePayList',
        method: 'get'
    },
    {
        url: '/manage/manage/pay/update',
        name: 'managePayEdit',
        method: 'post'
    },
    {
        url: '/manage/manage/shop/room-list',
        name: 'getShopRoomList',
        method: 'get'
    },
    {
        url: '/manage/manage/shop/room-delete',
        name: 'delShopRoom',
        method: 'get'
    },
    {
        url: '/manage/manage/shop/room-add',
        name: 'addShopRoom',
        method: 'post'
    },
    {
        url: '/manage/manage/shop/room-update',
        name: 'editShopRoom',
        method: 'post'
    },
    {
        url: '/manage/manage/shop-account/list',
        name: 'getShopAccountList',
        method: 'get'
    },
    {
        url: '/manage/manage/shop-account/update',
        name: 'editShopAccount',
        method: 'post'
    },
    {
        url: '/manage/manage/shop/roomImport',
        name: 'improtRoom',
        method: 'post'
    },
    {
        url: '/manage/static/shop_room_template.xlsx',
        name: 'downRoomTmp',
        method: 'get'
    },
    {
        url: '/manage/banner/manage/add',
        name: 'addBanner',
        method: 'post'
    },
    {
        url: 'manage/campaign/manage/getRealesedCampaign',
        method: 'get'
    },
    {
        url: '/manage/banner/manage/delete',
        name: 'delBanner',
        method: 'post'
    },
    {
        url: '/manage/banner/manage/list',
        name: 'getBannerList',
        method: 'post'
    },
    {
        url: '/manage/banner/manage/update',
        name: 'editBanner',
        method: 'post'
    },
    {
        url: '/manage/banner/manage/updateReleaseStatus',
        name: 'updateBannerStatus',
        method: 'post'
    },
    {
        url: 'manage/manage/group-ticket/add',
        name: 'addGroupTicket',
        method: 'post'
    },
    {
        url: 'manage/manage/group-ticket/groupImport',
        name: 'importGroupTicket',
        method: 'post'
    },
    {
        url: 'manage/manage/group-ticket/delete',
        name: 'delGroupTicket',
        method: 'post'
    },
    {
        url: 'manage/manage/group-ticket/getGroupTicketDepositPromotions',
        name: 'getGroupTicketRules',
        method: 'get'
    },
    {
        url: 'manage/manage/group-ticket/getGroupTicketSubjects',
        name: 'getGTSubject',
        method: 'get'
    },
    {
        url: 'manage/manage/group-ticket/list',
        name: 'getGroupTicketList',
        method: 'get'
    },
    {
        url: 'manage/manage/group-ticket/update',
        name: 'editGroupTicket',
        method: 'post'
    },
    {
        url: 'manage/manage/group-ticket/export',
        name: 'exportGroupTicket',
        down: true,
        method: 'get'
    },
    {
        url: 'manage/manage/region/getRegionTree',
        method: 'get'
    },
    {
        url: 'manage/manage/region/add',
        method: 'post',
        name: 'addRegion'
    },

    {
        url: 'manage/surrounding/manage/add',
        method: 'post',
        name: 'addSurround'
    },
    {
        url: 'manage/surrounding/manage/delete',
        method: 'post',
        name: 'delSurround'
    },
    {
        url: 'manage/surrounding/manage/list',
        method: 'post',
        name: 'getSurroundList'
    },
    {
        url: 'manage/surrounding/manage/update',
        method: 'post',
        name: 'editSurround'
    },
    {
        url: 'manage/attractinvestment/manage/dispose',
        method: 'post',
        name: 'operGshopdis'
    },
    {
        url: 'manage/attractinvestment/manage/list',
        method: 'post',
        name: 'getGshopdisList'
    },
    {
        url: 'manage/manage/menu/list',
        method: 'get',
        name: 'getMenuManageList'
    },
    {
        url: 'manage/manage/menu/add',
        method: 'post',
        name: 'addMenuManage'
    },
    {
        url: 'manage/manage/menu/menuTree',
        method: 'get',
        name: 'getMenuTree'
    },
    {
        url: 'manage/manage/menu/update',
        method: 'post',
        name: 'editMenuManage'
    },
    {
        url: 'manage/manage/permission/add',
        method: 'post',
        name: 'addRole'
    },
    {
        url: 'manage/manage/permission/delete',
        method: 'post',
        name: 'delRole'
    },
    {
        url: 'manage/manage/permission/list',
        method: 'get',
        name: 'getRoleList'
    },
    {
        url: 'manage/manage/permission/update',
        method: 'post',
        name: 'editRole'
    },
    {
        url: 'manage/manage/subject/querySubjectShopIds',
        method: 'get'
    },
    {
        url: 'manage/manage/shop-account/listSign',
        method: 'get',
        name: 'getShopSignList'
    },
    {
        url: 'manage/manage/shop-account/addSign',
        method: 'post',
        name: 'addShopSign'
    },
    {
        url: 'manage/manage/shop-account/deleteSign',
        method: 'post',
        name: 'delShopSign'
    },
    {
        url: 'manage/manage/shop-account/updateSign',
        method: 'post',
        name: 'editShopSign'
    },
    {
        url: 'manage/manage/shop-account/listPrinter',
        method: 'get',
        name: 'getShopPrinterList'
    },
    {
        url: 'manage/manage/shop-account/addPrinter',
        method: 'post',
        name: 'addShopPrinter'
    },
    {
        url: 'manage/manage/shop-account/deletePrinter',
        method: 'post',
        name: 'delShopPrinter'
    },
    {
        url: 'manage/manage/shop-account/updatePrinter',
        method: 'post',
        name: 'editShopPrinter'
    },
    {
        url: 'manage/manage/shop-account/updatePrinter',
        method: 'post',
        name: 'editShopPrinter'
    },
    {
        url: 'manage/report/posPay/list',
        method: 'get',
        name: 'getStatisticsPosList'
    },
    {
        url: 'manage/report/posPay/export',
        method: 'get',
        name: 'reportPosList',
        down: true
    },

    {
        url: 'manage/report/taobaoTicket/list',
        method: 'get',
        name: 'getStatisticsTaobaoTicketList'
    },
    {
        url: 'manage/report/taobaoTicket/export',
        method: 'get',
        name: 'reportTaobaoTicketList',
        down: true
    },

    {
        url: 'manage/report/debitCard/list',
        method: 'get',
        name: 'getStatisticsDebitCardList'
    },
    {
        url: 'manage/report/debitCard/export',
        method: 'get',
        name: 'reportDebitCardList',
        down: true
    },
    {
        url: 'manage/report/groupPay/list',
        method: 'get',
        name: 'getStatisticsGroupPayList'
    },
    {
        url: 'manage/report/groupPay/export',
        method: 'get',
        name: 'reportGroupPayList',
        down: true
    },
    {
        url: 'manage/report/cashPay/list',
        method: 'get',
        name: 'getStatisticsCashPayList'
    },
    {
        url: 'manage/report/cashPay/export',
        method: 'get',
        name: 'reportCashPayList',
        down: true
    },
    {
        url: 'manage/report/thirdPayHeadquarters/list',
        method: 'get',
        name: 'getStatisticsThirdPayHeadquartersList'
    },
    {
        url: 'manage/report/thirdPayHeadquarters/export',
        method: 'get',
        name: 'reportThirdPayHeadquartersList',
        down: true
    },
    {
        url: 'manage/report/thirdPayShop/list',
        method: 'get',
        name: 'getStatisticsPayShopList'
    },
    {
        url: 'manage/report/thirdPayShop/export',
        method: 'get',
        name: 'reportThirdPayPayShopList',
        down: true
    },

    {
        url: 'manage/report/unionPay/list',
        method: 'get',
        name: 'getStatisticsUnionPayList'
    },
    {
        url: 'manage/report/unionPay/export',
        method: 'get',
        name: 'reportUnionPayList',
        down: true
    },

    {
        url: 'manage/report/groupData/list',
        method: 'get',
        name: 'getStatisticsGroupDataList'
    },
    {
        url: 'manage/report/groupData/export',
        method: 'get',
        name: 'reportGroupDataList',
        down: true
    },

    {
        url: 'manage/report/debitCardConsume/list',
        method: 'get',
        name: 'getStatisticsDebitCardConsumeList'
    },
    {
        url: 'manage/report/debitCardConsume/export',
        method: 'get',
        name: 'reportDebitCardConsumeList',
        down: true
    },

    {
        url: 'manage/report/discountCardRecord/list',
        method: 'get',
        name: 'getVipUpList'
    },
    {
        url: 'manage/report/discountCardRecord/export',
        method: 'get',
        name: 'reportVipUpList',
        down: true
    },

    {
        url: 'manage/report/consumeOrderDeposit/list',
        method: 'get',
        name: 'consumeOrderDepositList'
    },
    {
        url: 'manage/report/consumeOrderDeposit/export',
        method: 'get',
        name: 'consumeOrderDepositExport',
        down: true
    },

    {
        url: 'manage/report/abnormalDeposit/list',
        method: 'get',
        name: 'getStatisticsAbnormalDepositList'
    },
    {
        url: 'manage/report/abnormalDeposit/export',
        method: 'get',
        name: 'reportAbnormalDepositList',
        down: true
    },

    {
        url: 'manage/report/discountCardAbnormal/list',
        method: 'get',
        name: 'getStatisticsDiscountCardAbnormalList'
    },
    {
        url: 'manage/report/discountCardAbnormal/export',
        method: 'get',
        name: 'reportDiscountCardAbnormalList',
        down: true
    },

    {
        url: 'manage/report/debitCardAbnormal/list',
        method: 'get',
        name: 'getStatisticsDebitCardAbnormallList'
    },
    {
        url: 'manage/report/debitCardAbnormal/export',
        method: 'get',
        name: 'reportDebitCardAbnormalList',
        down: true
    },

    {
        url: 'manage/report/comprehensivePerformance/list',
        method: 'get',
        name: 'getStatisticsComprehensivePerformanceList'
    },
    {
        url: 'manage/report/comprehensivePerformance/export',
        method: 'get',
        name: 'reportComprehensivePerformanceList',
        down: true
    },

    {
        url: 'manage/report/employeeWork/list',
        method: 'get',
        name: 'getStatisticsEmployeeWorkList'
    },
    {
        url: 'manage/report/employeeWork/export',
        method: 'get',
        name: 'reportEmployeeWorkList',
        down: true
    },

    {
        url: 'manage/memberCoupon/report/list',
        method: 'post',
        name: 'getStatisticstMemberCouponList'
    },
    {
        url: 'manage/memberCoupon/report/export',
        method: 'get',
        name: 'reportMemberCouponList',
        down: true
    },
    {
        url: 'manage/report/debitCardBalance/list',
        method: 'get',
        name: 'getMemberBalanceList'
    },
    {
        url: 'manage/report/consumeSubjectCount/export',
        method: 'get',
        name: 'exportConsumeSubject',
        down: true
    },

    {
        url: '/manage/report/employeeSpecialWork/list',
        method: 'get',
        name: 'getJsDzList'
    },
    {
        url: '/manage/report/employeeSpecialWork/export',
        method: 'get',
        name: 'exportJsDz',
        down: true
    },

    {
        url: '/manage/report/depositData/list',
        method: 'get',
        name: 'getDepositExportList'
    },
    {
        url: '/manage/report/depositData/export',
        method: 'get',
        name: 'exportDepositExport',
        down: true
    },

    {
        url: 'manage/report/consumeSubjectCount/list',
        method: 'get',
        name: 'getConsumeSubjectList'
    },

    {
        url: '/manage/manage/allinpayTerm/list',
        name: 'getAllinpayTermList',
        method: 'get'
    },
    {
        url: '/manage/manage/allinpayTerm/add',
        name: 'addAllinpayTerm',
        method: 'post'
    },
    {
        url: '/manage/manage/allinpayTerm/delete',
        name: 'delAllinpayTerm',
        method: 'post'
    },
    {
        url: '/manage/manage/allinpayTerm/init',
        name: 'initAllinpayTerm',
        method: 'post'
    },
    {
        url: '/manage/manage/allinpayTerm/push',
        name: 'pushAllinpayTerm',
        method: 'post'
    },
    {
        url: '/manage/manage/allinpayTerm/sync',
        name: 'syncAllinpayTerm',
        method: 'post'
    },
    {
        url: '/manage/manage/allinpayTerm/update',
        name: 'editAllinpayTerm',
        method: 'post'
    }
];

const setting: Sqi = new Sqi(list);
export default setting;
