<template>
    <el-cascader :options="city" :props="props" filterable collapse-tags v-model="val">
    </el-cascader>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import ServerEnum from '@/store/modules/global';
    import SqiExtend from '@/api';
    import city from './city.json';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    console.log(city);
    @Component({
        name: 'SelectBrandCoupon'
    })
    export default class SelectBrandCoupon extends SqiExtend {
        city: any = city;
        @ModelSync('value', 'change') val: any;
        // @Model('change') value: any;
        // get val() {
        //     if (this.value) {
        //         return this.value;
        //     } else {
        //         if (this._brandId) {
        //             if (this._couponId) {
        //                 return [this._brandId, this._couponId];
        //             } else {
        //                 return [this._brandId];
        //             }
        //         } else {
        //             return [];
        //         }
        //     }
        // }
        // set val(n) {
        //     this.$emit('change', n);
        //     this._brandId = n[0] ?? '';
        //     this._brandName = this.brandEnum[n[0]];
        //     this._couponId = n[1] ?? '';
        //     this._couponName = this.couponEnum[n[1]];
        // }

        // @PropSync('brandId') _brandId: any;
        // @PropSync('brandName') _brandName: any;
        // @PropSync('couponId') _couponId: any;
        // @PropSync('couponName') _couponName: any;

        get props() {
            return {
                label: 'Name',
                value: 'Name',
                multiple: true,
                children: 'ChildList'
            };
        }

        created() {
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
