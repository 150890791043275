import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/administer/debit/list',
        method: 'get',
        name: 'getGeRechargeList'
    },
    {
        url: 'manage/administer/debit/add',
        method: 'post',
        name: 'addGeRecharge'
    },
    {
        url: 'manage/administer/debit/import',
        method: 'post',
        name: 'importGeRecharge'
    },
    {
        url: 'manage/static/administer_debit_template.xlsx',
        method: 'post',
        name: 'downGeRechargeTmp'
    },
    {
        url: ' /manage/administer/debit/editRemark',
        method: 'post',
        name: 'GeRechargeEditRemark'
    },

    {
        url: 'manage/administer/vipCard/list',
        method: 'get',
        name: 'getGeVipList'
    },
    {
        url: 'manage/administer/vipCard/add',
        method: 'post',
        name: 'addGeVip'
    },
    {
        url: 'manage/administer/debit/cancel',
        method: 'post',
        name: 'cancelGeRecharge'
    },

    {
        url: 'manage/administer/vipCard/cancel',
        method: 'post',
        name: 'cancelGeVip'
    },

    {
        url: 'manage/administer/debit/export',
        method: 'get',
        down: true,
        name: 'exportRechargeGE'
    },

    {
        url: 'manage/administer/vipCard/export',
        method: 'get',
        name: 'exportVipGE'
    }
];

const ge: Sqi = new Sqi(list);
export default ge;
