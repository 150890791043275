import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { UserModule } from '@/store/modules/user';
import { REQUEST } from '@/constant/global';
import qs from 'qs';

const service = axios.create({
    timeout: 3e5
    // withCredentials: true,
    // baseURL: 'https://34kyya904974.vicp.fun/'
});
const RESPONSE = REQUEST.RESPONSE;
// Request interceptors
service.interceptors.request.use(
    (config: any) => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        // if (UserModule.token) {
        //   config.headers['X-Access-Token'] = UserModule.token
        // }

        if (config.method === REQUEST.METHOD.POST && config.data.constructor.name !== 'FormData') {
            config.headers['Content-Type'] = 'application/json';
            // config.data = qs.stringify(config.data, { allowDots: true });
        }
        if ([REQUEST.METHOD.GET, REQUEST.METHOD.DELETE].includes(config.method)) {
            config.paramsSerializer = function (params) {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            };
        }

        return config;
    },
    (error) => {
        console.log(error, 'request');
        Promise.reject(error);
    }
);

// Response interceptors
service.interceptors.response.use(
    (response: { data: any; headers: any; status?: any }) => {
        // Some example codes here:
        // code == 20000: success
        // code == 50001: invalid access token
        // code == 50002: already login in other place
        // code == 50003: access token expired
        // code == 50004: invalid user (user not exist)
        // code == 50005: username or password is incorrect
        // You can change this part for your own usage.
        // console.log(response)
        const res: { success: boolean; data: any; message: string } = response.data;
        if (response.headers['Set-Cookie']) {
            UserModule.SET_TOKEN(response.headers['Set-Cookie']);
        }

        if (response.status !== RESPONSE.STATUS.SUCCESS) {
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            });
            // if (response.code === RESPONSE.STATUS.NET_LOGIIN) {
            //   MessageBox.confirm(
            //     'You have been logged out, try to login again.',
            //     'Log out',
            //     {
            //       confirmButtonText: 'Relogin',
            //       cancelButtonText: 'Cancel',
            //       type: 'warning'
            //     }
            //   ).then(() => {
            //     // UserModule.ResetToken()
            //     location.href="#/login";
            //     location.reload() // To prevent bugs from vue-router
            //   })
            // }

            return Promise.reject(new Error(res.message || 'Error'));
        } else {
            const { data, headers } = response;
            if (!/application\/json\;\s?charset=UTF\-8/gi.test(headers['content-type'])) {
                console.log(response);
                const fileName = headers['content-disposition'].replace(
                    /\w+;\s+filename=(.*)/,
                    '$1'
                );
                // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
                //const blob = new Blob([JSON.stringify(data)], ...)
                const blob = new Blob([data], { type: headers['content-type'] });
                let dom: any = document.createElement('a');
                let url = window.URL.createObjectURL(blob);
                dom.href = url;
                dom.download = decodeURI(fileName);
                dom.style.display = 'none';
                document.body.appendChild(dom);
                dom.click();
                dom.parentNode.removeChild(dom);
                window.URL.revokeObjectURL(url);
            }
            return res;
        }
    },
    (error: { response: { status: any; data: { message: any } }; message: any }) => {
        console.log('------2-----');
        switch (error.response.status) {
            case RESPONSE.STATUS.NOT_LOGIN:
                location.href = '#/login';
                break;
            default:
                Message({
                    message: error.response?.data?.message || error.message,
                    type: 'error',
                    duration: 5 * 1000
                });
                break;
        }

        return Promise.reject(error);
    }
);

export default service;
