var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-main"},[_c('el-table',_vm._b({key:_vm.comKey,ref:"table",attrs:{"data":_vm.data,"row-class-name":"table-padding-left","border":_vm.tableConfig.border || false,"stripe":"","header-cell-class-name":"vt-header"},on:{"select":function($event){return _vm.$emit('select', $event)},"select-all":function($event){return _vm.$emit('select-all', $event)},"select-change":function($event){return _vm.$emit('select-change', $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('el-empty',{attrs:{"description":"暂无数据"}})]},proxy:true}])},'el-table',_vm.tableConfig,false),_vm._l((_vm.rowConfigAppendBrand),function(i,k){return _c('el-table-column',_vm._b({key:k,attrs:{"show-overflow-tooltip":"","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(slot){return [_vm._t("header",function(){return [_vm._v(" "+_vm._s(slot.column.label)+" ")]},null,slot)]}},(!i.site && i.prop)?{key:"default",fn:function({ row }){return [(i.copy)?[_c('span',{on:{"click":function($event){_vm.copyText(_vm.getTableProp(row, i))}}},[_vm._v(" "+_vm._s(_vm.getTableProp(row, i))+" ")])]:(i.switch)?[_c('el-switch',{attrs:{"value":row[i.prop],"disabled":""}})]:(
                        i.isStatus ||
                        (['channel', 'brandId', 'invoiceStatus', 'brandName'].includes(
                            i.prop
                        ) &&
                            !i.ignore)
                    )?[_c('el-tag',{attrs:{"type":i.sType || _vm.getFontColor(row, i)}},[_vm._v(" "+_vm._s(_vm.getTableProp(row, i))+" ")])]:[_vm._v(" "+_vm._s(_vm.getTableProp(row, i))+" ")]]}}:(i.site)?{key:"default",fn:function({ row, $index }){return [_vm._t(i.site,null,{"row":row,"index":$index})]}}:null],null,true)},'el-table-column',i,false),[(i.chirden)?_vm._l((i.chirden),function(j,index){return _c('el-table-column',_vm._b({key:index,scopedSlots:_vm._u([(!j.site && j.prop)?{key:"default",fn:function({ row }){return [(j.copy)?[_c('span',{on:{"click":function($event){_vm.copyText(_vm.getTableProp(row, j))}}},[_vm._v(" "+_vm._s(_vm.getTableProp(row, j))+" ")])]:(j.switch)?[_c('el-switch',{attrs:{"value":row[j.prop],"disabled":""}})]:[_vm._v(" "+_vm._s(_vm.getTableProp(row, j))+" ")]]}}:null],null,true)},'el-table-column',j,false))}):_vm._e()],2)}),1),(_vm.showPage)?[_c('view-pagination',{attrs:{"v-if":_vm.isPage,"total":_vm.total,"hide-on-single-page":!_vm.isPage,"current-page":_vm.Page,"page-size":_vm.Size},on:{"update:currentPage":function($event){_vm.Page=$event},"update:current-page":function($event){_vm.Page=$event},"update:pageSize":function($event){_vm.Size=$event},"update:page-size":function($event){_vm.Size=$event}}},[_vm._t("page")],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }