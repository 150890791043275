import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/org/manage/add',
        name: 'addOrg',
        method: 'post'
    },
    {
        url: 'manage/org/manage/delete',
        name: 'deleteOrg',
        method: 'post'
    },
    {
        url: 'manage/org/manage/list',
        name: 'getOrgList',
        method: 'get'
    },
    {
        url: 'manage/org/manage/orgTypeList',
        name: 'getOrgTypeList',
        method: 'get'
    },
    {
        url: 'manage/org/manage/parentList',
        name: 'getOrgParentList',
        method: 'get'
    },
    {
        url: 'manage/org/manage/update',
        name: 'updateOrg',
        method: 'post'
    }
];

const org: Sqi = new Sqi(list);
export default org;
