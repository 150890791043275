<template>
    <el-cascader
        :options="orgTree"
        v-model="val"
        collapse-tags
        filterable
        :props="{ checkStrictly: true, emitPath: false }"
    ></el-cascader>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import FormStructure from '@/utils/FormStructure';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import global from '@/store/modules/global';

    @Component({
        name: 'SelectOrg'
    })
    export default class SelectOrg extends SqiExtend {
        @ModelSync('value', 'change') val: any;
        @Prop({ default: false }) multiple?: boolean;

        get orgTree() {
            return global.org;
        }

        async created() {
            if (global.org) {
                await global.GET_ORG_TREE();
            }
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
