<template>
	<div class="view-video">

		<div
			class="video-box"
			@click="vis=true"
		>
			<i class="el-icon-video-camera"></i>
		</div>
		<el-dialog
			:visible.sync="vis"
			width="400px"
			top="10%"
		>
			<video
				ref="video"
				:src="src"
				controls
			></video>
		</el-dialog>
	</div>
</template>
<script lang="ts">
	import {
		Component,
		Inject,
		Model,
		Prop,
		Vue,
		Watch,
		Emit,
		PropSync
	} from 'vue-property-decorator';

	@Component({
		name: 'ViewVideo'
	})
	export default class ViewVideo extends Vue {
		@Prop() src?: string;
		vis: boolean = false;

		@Watch('vis')
		visChange(n) {
			let video: any = this.$refs.video;
			if (n) {
				video?.play();
			} else {
				video?.pause();
			}
		}
	}
</script>
<style lang="scss" scoped>
	.view-video {
		width: 100px;
		height: 100px;
		border-radius: 5px;
		border: 1px solid #eee;
		font-size: 30px;
		color: #666;
		cursor: pointer;
		.video-box {
			width: 100%;
			height: 100%;
			@include flex-layout(center);
		}
	}
	video {
		width: 100%;

		height: auto;
	}
</style>