<template>
    <el-date-picker
        v-model="val"
        :type="type"
        :value-format="valueFormat"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
    >
    </el-date-picker>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        PropSync
    } from 'vue-property-decorator';

    @Component({
        name: 'DateRangeDouble',
        components: {}
    })
    export default class DateRangeDouble extends Vue {
        @PropSync('stime') _stime: any;
        @PropSync('etime') _etime: any;
        @Prop({ default: 'daterange', type: String }) type: string | undefined;
        @Prop({ default: 'yyyy-MM-dd', type: String }) valueFormat: string | undefined;
        get val() {
            return [this._stime || '', this._etime || ''];
        }
        set val(time) {
            this._stime = time?.[0] ?? '';
            this._etime = time?.[1] ?? '';
        }
    }
</script>
<style lang="scss" scoped>
    .el-dialog__header {
    }
    .el-dialog__body {
        padding-top: 0;
    }
</style>
