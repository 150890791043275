import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: '/manage/complaints/manage/list',
        method: 'post',
        name: 'getComplaintList'
    },
    {
        url: '/manage/complaints/manage/reply',
        method: 'post',
        name: 'replyComplaint'
    },
    {
        url: '/manage/complaints/manage/updateStatus',
        method: 'post',
        name: 'updateComplaintStatus'
    }
];

const complaints: Sqi = new Sqi(list);
export default complaints;
