<template>
    <div class="view-time-list">
        <el-date-picker
            v-model="timeList[k]"
            type="datetime"
            placeholder=""
            align="right"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="date-item"
            :editable="false"
            prefix-icon=""
            v-for="(i, k) of timeList"
            @change="changeItem($event, k)"
            :key="k"
            size="mini"
        >
        </el-date-picker>
        <el-date-picker
            v-model="addVal"
            type="datetime"
            placeholder=""
            align="right"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            ref="dateAdd"
            class="date-add"
            :editable="false"
            prefix-icon="el-icon-circle-plus-outline"
            @change="changeAdd"
        >
        </el-date-picker>
    </div>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    @Component({
        name: 'ViewDateTimeList'
    })
    export default class ViewDateTimeList extends SqiExtend {
        @ModelSync('value', 'change', { default: () => [] }) timeList: any;
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        @PropSync('name') _name: any;
        cardList: any = [];
        addVal: any = '';
        listEnum: any = [];

        showAdd() {
            console.log(this.$refs.dateAdd);
        }
        changeAdd(e) {
            this.timeList = [...this.timeList, e];
            this.addVal = '';
        }
        changeItem(e, k) {
            if (!e) {
                this.timeList.splice(k, 1);
            }
        }
        created() {
            // this.setForm();
        }
    }
</script>
<style lang="scss">
    .date-add .el-input__inner {
        display: none;
    }
    .date-item {
        width: 150px !important;
        margin-left: 5px;
    }
    .date-item .el-input__inner {
        padding: 0 5px;
    }
    .date-item .el-input__prefix {
        display: none;
    }
    .date-add .el-input__icon.el-icon-circle-plus-outline {
        font-size: 30px;
    }
</style>
<style lang="scss" scoped>
    .date-add {
        width: 40px;
        height: 40px;
        border: none;
        cursor: pointer;
        vertical-align: middle;
    }
    .view-time-list {
        width: 100%;
    }
</style>
