<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script lang="ts">
    // import { Component, Vue } from 'vue-property-decorator';
    import VueView, { Component, Watch } from '@/vueExtend';
    import Vue from 'vue';

    @Component({
        name: 'App'
    })
    export default class extends Vue {}
</script>
