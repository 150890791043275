<template>
    <div style="border: 1px solid #ccc">
        <!-- <mavon-editor style="width: 100%"> </mavon-editor> -->
        <v-md-editor
            v-model="val"
            :height="height"
            :disabled-menus="[]"
            @upload-image="handleUploadImage"
        ></v-md-editor>
    </div>
</template>
<script lang="ts">
    import { Component, Prop, Vue, ModelSync } from 'vue-property-decorator';
    import SqiExtend from '@/api';

    @Component({
        name: 'Hamburger'
    })
    export default class extends SqiExtend {
        @ModelSync('value', 'change') val: any;
        @Prop({ default: '400px', type: String }) height;

        handleUploadImage(event, insertImage, files) {
            console.log(files);
            this.$uploadFile(files[0]).then((res) => {
                res.success &&
                    insertImage({
                        url: res.data,
                        desc: files[0].name
                        // width: 'auto',
                        // height: 'auto',
                    });
            });
            // 此处只做示例
            //   insertImage({
            //     url:
            //       'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1269952892,3525182336&fm=26&gp=0.jpg',
            //     desc: '七龙珠',
            //     // width: 'auto',
            //     // height: 'auto',
            //   });
        }
    }
</script>
<style lang="scss" scoped></style>
