import userManage from './userManage';
import dict from './dict';

import { Component, Inject, Model, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import brand from './brand';
import common from './common';
import discountCard from './discoutnCard';
import contract from './contract';
import activity from './activity';
import point from './point';
import recharge from './recharge';
import ge from './ge';
import order from './order';
import recommender from './recommender';
import org from './organization';
import setting from './setting';
import evaluate from './evaluate';
import complaints from './complaint';
import video from './video';
import subject from './subject';
import relation from './relation';
import gzh from './gzh';
import testShop from './testShop';

@Component
export default class SqiExtend extends Vue {
    get $sqi() {
        return {
            ...userManage,
            ...dict,
            ...brand,
            ...common,
            ...discountCard,
            ...contract,
            ...activity,
            ...point,
            ...recharge,
            ...ge,
            ...order,
            ...recommender,
            ...org,
            ...setting,
            ...evaluate,
            ...complaints,
            ...video,
            ...subject,
            ...relation,
            ...gzh,
            ...testShop
        };
    }

    $uploadFile(file: any, group = 'COMMON_IMAGE') {
        let fd = new FormData();
        fd.append('file', file);
        fd.append('group', group);
        return this.$sqi.upload(fd);
        // await axios.post(this.$sqi.upload.url, fd);
    }
    $arrayToObject(list: any[]) {
        let obj: any = {};
        list.forEach((i, k) => (obj[k] = i));
        return obj;
    }
}
