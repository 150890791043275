import Layout from '@/layout/index.vue';
import NullVue from '@/layout/Null.vue';

export default [
    {
        path: '/',
        component: Layout,
        redirect: '/home',
        name: 'Index'
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/index.vue'),
        meta: {
            title: '首页',
            icon: 'el-icon-s-home'
        }
    },
    {
        path: '/setpass',
        name: 'SetPass',
        component: () => import(/* webpackChunkName: "home" */ '@/views/setting/user/setPass.vue'),
        meta: {
            title: '修改密码',
            hidden: true,
            icon: 'el-icon-s-custom'
        }
    },
    {
        path: '/member',
        component: Layout,
        redirect: '/member/manage',
        name: 'Member',
        meta: {
            title: '用户管理',
            icon: 'el-icon-user-solid'
        }
    },
    {
        path: '/member/manage',
        name: 'MemberMange',
        component: () =>
            import(/* webpackChunkName: "userManage" */ '@/views/userManage/index.vue'),
        meta: {
            title: '用户管理',
            icon: 'el-icon-user-solid'
        }
    },
    {
        path: '/contract',
        component: Layout,
        redirect: '/contract/manage',
        name: 'Contract',
        meta: {
            title: '合同管理',
            icon: 'el-icon-notebook-1'
        }
    },
    {
        path: '/contract/manage',
        name: 'ContractMange',
        component: () => import(/* webpackChunkName: "userManage" */ '@/views/contract/index.vue'),
        meta: {
            title: '合同管理',
            icon: 'el-icon-notebook-1'
        }
    },

    {
        path: '/testShop',
        component: Layout,
        redirect: '/testShop/manage',
        name: 'TestShop',
        meta: {
            title: '检测商品管理',
            icon: 'el-icon-suitcase-1'
        }
    },
    {
        path: '/testShop/manage',
        name: 'TestShopMange',
        component: () => import(/* webpackChunkName: "userManage" */ '@/views/testShop/index.vue'),
        meta: {
            title: '检测商品',
            icon: 'el-icon-suitcase-1'
        }
    },
    {
        path: '/testOrder',
        component: Layout,
        redirect: '/testOrder/manage',
        name: 'TestOrder',
        meta: {
            title: '检测订单管理',
            icon: 'el-icon-s-order'
        }
    },
    {
        path: '/testOrder/manage',
        name: 'TestOrderMange',
        component: () => import(/* webpackChunkName: "userManage" */ '@/views/testOrder/index.vue'),
        meta: {
            title: '检测订单列表',
            icon: 'el-icon-s-order'
        }
    },
    {
        path: '/testOrder/details',
        name: 'TestOrderDetails',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/testOrder/details.vue'),
        meta: {
            title: '订单详情',
            parent: 'TestOrder',
            hidden: true
        }
    },
    {
        path: '/page',
        component: Layout,
        redirect: '/testOrder/manage',
        name: 'Page',
        meta: {
            title: '页面管理',
            icon: 'el-icon-mobile'
        }
    },
    {
        path: '/page/manage',
        name: 'PageMange',
        component: () => import(/* webpackChunkName: "userManage" */ '@/views/page/index.vue'),
        meta: {
            title: '页面管理',
            icon: 'el-icon-mobile'
        }
    },
    {
        path: '/page/manage/edit',
        name: 'PageMangeEdit',
        component: () => import(/* webpackChunkName: "activity" */ '@/views/page/edit.vue'),
        meta: {
            title: '页面编辑',
            parent: 'PageMange',
            hidden: true
        }
    },
    {
        path: '/config/manage',
        name: 'ConfigMange',
        component: () => import(/* webpackChunkName: "userManage" */ '@/views/config/index.vue'),
        meta: {
            title: '系统配置',
            icon: 'el-icon-setting'
        }
    },
    {
        path: '/config',
        component: Layout,
        redirect: '/config/manage',
        name: 'Config',
        meta: {
            title: '系统配置',
            icon: 'el-icon-setting'
        }
    }
    // {
    // 	path: '/member/brand',
    // 	name: 'MemberMangeBrand',
    // 	component: () =>
    // 		import(/* webpackChunkName: "userManage" */ '@/views/userManage/member/brand.vue'),
    // 	meta: {
    // 		title: '个人详情',
    // 		hidden: true,
    // 		parent: 'MemberMange',

    // 		icon: 'dashboard'
    // 	}
    // },
    // {
    // 	path: '/brand/setting',
    // 	name: 'BrandSetting',
    // 	component: () => import(/* webpackChunkName: "brand" */ '@/views/brand/setting/index.vue'),
    // 	meta: {
    // 		title: '品牌设置',
    // 		icon: 'el-icon-setting'
    // 	}
    // },
    // {
    // 	path: '/brand/identity',
    // 	name: 'BrandIdentity',
    // 	component: () => import(/* webpackChunkName: "brand" */ '@/views/brand/identity/index.vue'),
    // 	meta: {
    // 		title: '会员身份设置',
    // 		icon: 'el-icon-bank-card'
    // 	}
    // }
];
