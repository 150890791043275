import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/contract/list',
        name: 'getContractList',
        method: 'get'
    },
    {
        url: 'manage/contract/add',
        name: 'addContract',
        method: 'post'
    },
    {
        url: 'manage/contract/edit',
        name: 'editContract',
        method: 'post'
    }
];

const contract: Sqi = new Sqi(list);
export default contract;
