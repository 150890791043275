import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
	{
		url: 'manage/testShop/list',
		method: 'get',
		name: 'getTestShopList'
	},
	{
		url: 'manage/testShop/add',
		method: 'post',
		name: 'addTestShop'
	},
	{
		url: 'manage/testShop/edit',
		method: 'post',
		name: 'editTestShop'
	},
	{
		url: 'manage/testShop/del',
		method: 'delete',
		name: 'delTestShop'
	},
	{
		url: 'manage/testShop/usableList',
		method: 'get',
		name: 'getTestShopUsable'
	}
];

const ge: Sqi = new Sqi(list);
export default ge;
