<template>
    <editor api-key="no-api-key" :init="init" v-model="val" />
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import Editor from '@tinymce/tinymce-vue';
    import SqiExtend from '@/api';
    import axios from 'axios';

    @Component({
        name: 'RichText',
        components: {
            Editor
        }
    })
    export default class RichText extends SqiExtend {
        @ModelSync('value', 'change') val: any;
        get init() {
            return {
                height: 1000,
                menubar: false,
                language_url: '/tinymce/zh_CN.js',
                language: 'zh_CN',
                // skin_url: '/tinymce/skins/lightgray'
                images_upload_handler: this.upload,
                file_picker_callback(file, success, failure) {
                    console.log(file, success, failure);
                },
                plugins:
                    'code print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave   autoresize',
                // plugins:
                //     'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave   autoresize formatpainter',
                toolbar:
                    'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
                     styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                     table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs importword kityformula-editor'
                // toolbar:
                //     'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
                //      styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                //      table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs importword kityformula-editor'
            };
        }
        mounted() {
            // tinymce.ini({});
        }
        upload(file, success, failure) {
            console.log(file, success, failure);

            let form = new FormData();
            form.append('file', file.blob(), file.filename());
            form.append('type', 'img');
            axios.post(this.$sqi.upload.url, form).then(({ data: r }) => {
                if (r.success) {
                    success(r.data);
                } else {
                    failure(r);
                }
            });
        }
        created() {
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
