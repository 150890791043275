import request from '@/utils/request';
import { AxiosInstance } from 'axios';
import { Loading } from 'element-ui';

import { type } from 'os';
export interface SqiItemConfig {
    url: string;
    method: string;
    name?: string;
    isloading?: boolean;
    down?: boolean;
}
interface responseData {
    success: boolean;
    message: string;
    data: any;
}
// interface response {
//     data: any;
//     success: boolean;
//     message: string;
// }
export class Sqi {
    [key: string]: any;
    constructor(list: SqiItemConfig[]) {
        this.getSqiList(list);
        // this.arrayToObject = Sqi.arrayToObject;
    }

    // loading: boolean = false;
    load: any = null;
    get loading() {
        let isL = this.task.length;

        return isL;
    }
    private loadOper() {
        // if (this.loading) {
        //     this.load = Loading.service({
        //         lock: true,
        //         text: 'Loading',
        //         spinner: 'el-icon-loading',
        //         background: 'rgba(0, 0, 0, 0.7)'
        //     });
        // } else if (this.load) {
        //     this.load?.close?.();
        //     this.load = null;
        // }
    }
    task: any = [];

    private push_task(key: string) {
        this.task.push(key);
        this.loadOper();
        // console.log(key, this.loading);
        // if(this.loading){
        //     this.
        // }
    }
    private close_task(key: string) {
        let index = this.task.indexOf(key);
        if (index != -1) {
            this.task.splice(index, 1);
        }
        this.loadOper();
    }
    private getSqiList(list: SqiItemConfig[]) {
        let _this = this;
        list.forEach((i: SqiItemConfig) => {
            const url: string = i.url,
                name: string = i.name || url.split('/')[url.split('/').length - 1],
                type: any = i.method || 'get';

            this[name] = function api(data: any): Promise<responseData> {
                console.log(data);
                if (data && getValidats(data).length > 0) {
                    const validats = getValidats(data);
                    console.log(validats);
                    return Promise.all(validats.map((i) => i()))
                        .catch((e) => {
                            console.log(e);
                            throw new Error();
                        })
                        .then(req);
                } else {
                    return req();
                }

                async function req() {
                    console.log(data?.constructor?.name, '----2-----');

                    data = ['FormData', 'Array'].includes(data?.constructor?.name)
                        ? data
                        : Object.assign({}, data);

                    const form = data,
                        header = { responseType: i.down ? 'blob' : '' }, //{data:encrypt(data)},
                        agrs = [
                            url,
                            ['get', 'delete'].includes(type) ? { params: form, ...header } : form
                        ],
                        method: any = (request as any)[type];
                    let key = Math.random() * 1e8 + '_' + Date.now(),
                        load = null;

                    if (i.isloading || i.down) {
                        load = Loading.service({
                            lock: true,
                            text: 'Loading',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                    }
                    _this.push_task(key);
                    let res = await method(...agrs);
                    _this.close_task(key);
                    // await _this.wait(3e3);
                    load?.close?.();
                    return res;
                }
            };
            this[name].url = url;
        });
    }

    wait(s: number) {
        return new Promise((res, rej) => {
            setTimeout(res, s);
        });
    }
}

function getValidats(form: any): any {
    return (
        form.validats && form.validats.constructor?.name == 'Array' ? form.validats : []
    ).concat(
        Object.values(form)
            .filter((i) => {
                return i && ['Array', 'FsData', 'Object'].includes(i.constructor?.name);
            })
            .map((i) => {
                if (i.constructor.name === 'FsData') {
                    return i.validats;
                } else {
                    return getValidats(i);
                }
            })
            .flat(4)
    );
}
export default Sqi;
