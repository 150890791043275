import Vue from 'vue';

import common from './api/common';

import 'normalize.css';
import ElementUI, { Button } from 'element-ui';
import SvgIcon from 'vue-svgicon';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router/index';
import '@/icons/components';
import '@/permission';
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

import hljs from 'highlight.js';

VMdEditor.use(githubTheme, {
    Hljs: hljs
});
const isWide = window.window.outerWidth > 1920;
Vue.use(VMdEditor);
// import api from '@/api'
Vue.use(ElementUI, { size: isWide ? 'small' : 'mini' });
Vue.use(SvgIcon, {
    tagName: 'svg-icon',
    defaultWidth: '1em',
    defaultHeight: '1em'
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,

    render: (h) => h(App)
}).$mount('#app');
