import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/user/list',
        method: 'get',
        name: 'getUserList'
    },
    {
        url: 'manage/user/add',
        method: 'post',
        name: 'addUser'
    },
    {
        url: 'manage/user/edit',
        method: 'post',
        name: 'editUser'
    },
    {
        url: 'manage/address/getListByUserId',
        method: 'get',
        name: 'getAddressByUserId'
    },

    {
        url: 'manage/address/edit',
        method: 'post',
        name: 'editAddress'
    },

    {
        url: 'manage/address/del',
        method: 'delete',
        name: 'delAddress'
    },

    {
        url: 'manage/user/auditeContract',
        method: 'post',
        name: 'auditeContract'
    },

    {
        url: 'manage/user/auditeStore',
        method: 'post',
        name: 'auditeStore'
    }
];

const userManage: Sqi = new Sqi(list);
export default userManage;
