<script lang="ts">
	import {
		Component,
		Inject,
		Model,
		Prop,
		Vue,
		Watch,
		Emit
	} from 'vue-property-decorator';
	import { Dialog } from 'element-ui';
	import { mixins } from 'vue-class-component';

	const EL_TYPES: any = [
		'primary',
		'success',
		'warning',
		'danger',
		'info',
		'text'
	];

	@Component({
		name: 'ViewDialog',
		components: {}
	})
	export default class ViewDialog extends mixins(Dialog) {}
</script>
<style lang="scss" scoped>
	.el-dialog__header {
	}
	.el-dialog__body {
		padding-top: 0;
	}
</style>
