<template>
    <el-input v-model="val" type="text">
        <template #suffix>
            <el-popover
                placement="top-center"
                title="提示"
                width="200"
                trigger="hover"
                content="区间值填写请用 '最小值-最大值' 方式填写,最大值最小值可以不填，横杠不填写表示准确值。例如'1-10'表示1至10，'5'表示精确值5，'-5'表示小于等于5的值，'5-'表示大于等于5的值"
            >
                <i slot="reference" class="el-icon-warning-outline"></i>
            </el-popover>
        </template>
    </el-input>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORMad_ITEMad_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import ServerEnum from '@/store/modules/global';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    @Component({
        name: 'NumberRange'
    })
    export default class NumberRange extends SqiExtend {
        @PropSync('snum') ad_snum: any;
        @PropSync('num') ad_num: any;
        @PropSync('enum') ad_enum: any;
        get val() {
            if (this.ad_snum || this.ad_enum || this.isFirstMax) {
                return `${this.ad_snum ?? ''}-${this.ad_enum ?? ''}`;
            } else {
                return this.ad_num;
            }
        }
        @Watch('ad_snum')
        @Watch('ad_snum')
        @Watch('ad_snum')
        cc(n, o) {
            console.log(n, '--', o, 'num-range');
        }
        isFirstMax: boolean = false;
        set val(n) {
            if (/\-/.test(n)) {
                this.isFirstMax = true;
                let arr = n.split('-');
                this.ad_snum = arr[0];
                this.ad_enum = arr[1];
                this.ad_num = '';
            } else {
                this.isFirstMax = false;
                this.ad_num = n;
                this.ad_snum = '';
                this.ad_enum = '';
            }
            console.log('snum' + this.ad_snum, 'num' + this.ad_num, 'enum' + this.ad_enum);
        }
    }
</script>
<style lang="scss" scoped>
    i.el-icon-warning-outline {
        color: #e6a23c;
        cursor: pointer;
    }
</style>
