<template>
    <div :class="classObj" class="app-wrapper">
        <div class="app-mask">
            <ul>
                <li v-for="i of 10">
                    <span v-if="i % 2 == 1"></span>
                    <span v-for="j of 10">{{ isTest ? 'TEST-' : '' }}{{ name }}</span>
                </li>
            </ul>
        </div>
        <div class="ui-header">
            <div class="uh-tit">
                <div class="uh-logo">
                    <!-- <img src="/logo/sh.png" alt="" /> -->
                </div>
                <span>客户管理系统</span>
            </div>
            <div class="right-menu">
                <div class="right-menu-item" v-if="msg.complaintSize">
                    <el-badge
                        :value="msg.complaintSize"
                        @click.native="$router.push('/complaints/list')"
                    >
                        <i class="el-icon-document"></i>
                    </el-badge>
                </div>
                <div class="right-menu-item" v-if="msg.evaluateSize">
                    <el-badge
                        :value="msg.evaluateSize"
                        @click.native="$router.push('/evaluate/list')"
                    >
                        <i class="el-icon-chat-line-square"></i>
                    </el-badge>
                </div>

                <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                    <div class="avatar-wrapper">
                        <!-- <img class="user-avatar" src="@/assets/icon/default.png" /> -->
                        <!-- <i class="el-icon-caret-bottom" /> -->
                        {{ name }}
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <span @click="$router.push('/setpass')">修改密码</span>
                        </el-dropdown-item>
                        <el-dropdown-item divided>
                            <span style="display: block" @click="LogOut">退出</span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div
            v-if="classObj.mobile && sidebar.opened"
            class="drawer-bg"
            @click="handleClickOutside"
        />
        <sidebar class="sidebar-container" />
        <div class="main-container">
            <navbar />
            <app-main />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from 'vue-property-decorator';
    import { mixins } from 'vue-class-component';
    import { DeviceType, AppModule } from '@/store/modules/app';
    import { AppMain, Navbar, Sidebar } from './components';
    import ResizeMixin from './mixin/resize';
    import { UserModule } from '@/store/modules/user';
    import axios from 'axios';

    @Component({
        name: 'Layout',
        components: {
            AppMain,
            Navbar,
            Sidebar
        }
    })
    export default class extends mixins(ResizeMixin) {
        get isTest() {
            return location.host !== 'mini.xiaoerdf.com';
        }
        get name() {
            return UserModule.name;
        }
        get LogOut() {
            return UserModule.LogOut;
        }
        get classObj() {
            return {
                hideSidebar: !this.sidebar?.opened,
                openSidebar: this.sidebar?.opened,
                withoutAnimation: this.sidebar?.withoutAnimation,
                mobile: this.device === DeviceType.Mobile
            };
        }

        private handleClickOutside() {
            AppModule.CloseSideBar(false);
        }

        token: string = '';
        getToken() {
            // axios.get('manage/push/token').then(({ data }) => {
            //     console.log(data);
            //     this.token = data.data;
            //     this.openSocket();
            // });
        }
        msg: any = {};
        openSocket() {
            let eventSource = new EventSource('/manage/push/connect?token=' + this.token, {
                withCredentials: true
            });

            eventSource.addEventListener('open', function (e) {
                console.log('open successfully');
            });

            eventSource.addEventListener('message', (e) => {
                console.log(JSON.parse(e.data));
                let data = JSON.parse(e.data);
                this.msg = data;
                if (window.Notification && Notification.permission !== 'denied') {
                    Notification.requestPermission(function (status) {
                        var n = new Notification('新任务未处理', {
                            body: `新增投诉建议${data.complaintSize}条！\n新增差评${data.evaluateSize}条！`
                        });
                    })
                        .then((r) => {
                            console.log(r);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                }
            });

            eventSource.addEventListener('error', function (err) {
                console.log(err);
                // 类似的返回信息验证，这里是实 例
                err && err.status === 401 && console.log('not authorized');
            });
        }

        getNotification() {
            if (window.Notification && Notification.permission !== 'granted') {
                console.log('通知权限未获取');

                Notification.requestPermission((status) => {
                    console.log(`通知权限获取结果`, status);
                });
            } else {
                console.log('通知权限已获取');
            }
        }
        mounted() {
            this.getNotification();
            this.getToken();
        }
    }
</script>

<style lang="scss" scoped>
    .app-mask {
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: -25vh;
        top: -25vh;
        z-index: 9999;
        color: rgba(#aaa, 0.3);
        transform: rotate(-45deg);
        pointer-events: none;
        ul {
            width: 100%;
            height: 100%;
            li {
                width: 100%;
                height: 20vh;
                display: flex;
                justify-content: space-between;
                font-size: 20px;
            }
        }
    }
    .app-wrapper {
        @include clearfix;
        position: relative;
        height: 100%;
        width: 100%;
    }
    .ui-header {
        width: 100%;
        height: $headerHeight;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 1001;
        background-color: #4338ca;
        color: #fff;
    }

    .drawer-bg {
        background: #000;
        opacity: 0.3;
        width: 100%;
        top: 0;
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    .main-container {
        height: calc(100% - #{$headerHeight});
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15) inset;

        transition: margin-left 0.28s;
        overflow-y: auto;
        margin-left: $sideBarWidth;
        position: relative;
        top: $headerHeight;
    }

    .sidebar-container {
        transition: width 0.28s;
        width: $sideBarWidth !important;
        height: calc(100% - #{$headerHeight});
        position: fixed;
        font-size: 0px;
        top: $headerHeight;
        bottom: 0;
        left: 0;
        z-index: 1001;
        overflow: hidden;
    }

    .hideSidebar {
        .main-container {
            margin-left: 54px;
        }

        .sidebar-container {
            width: 54px !important;
        }
    }

    /* for mobile response 适配移动端 */
    .mobile {
        .main-container {
            margin-left: 0px;
        }

        .sidebar-container {
            transition: transform 0.28s;
            width: $sideBarWidth !important;
        }

        &.openSidebar {
            position: fixed;
            top: 0;
        }

        &.hideSidebar {
            .sidebar-container {
                pointer-events: none;
                transition-duration: 0.3s;
                transform: translate3d(-$sideBarWidth, 0, 0);
            }
        }
    }
    .uh-logo {
        height: 50px;
        width: auto;
        flex-shrink: 0;
        img {
            height: 100%;
        }
    }
    .ui-header {
        padding: 0 20px;
        @include flex-layout;
        .uh-tit {
            width: 300px;
            @include flex-layout;
            font-size: 18px;
            line-height: 17px;
            color: #fff;
        }
    }
    .withoutAnimation {
        .main-container,
        .sidebar-container {
            transition: none;
        }
    }
    .right-menu {
        float: right;
        height: 100%;
        line-height: 1;
        width: auto;
        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }
        .el-badge {
            position: relative;
            top: 15px;
            margin-right: 30px;
            cursor: pointer;
        }
        .el-badge [class^='el-icon-'] {
            color: #fff;
            font-size: 35px;
        }

        .avatar-container {
            margin-right: 30px;

            .avatar-wrapper {
                margin-top: 8px;
                position: relative;
                color: #fff;
                font-weight: bold;
                line-height: 42px;
                text-decoration: underline;
                .user-avatar {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    margin-right: 5px;
                    vertical-align: middle;
                }

                .el-icon-caret-bottom {
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 25px;
                    font-size: 12px;
                }
            }
        }
    }
</style>
