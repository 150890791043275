const _sp = supZero;

class Time extends Date {
    static jsonConstructor(json: string, sp: RegExp = /\-|\ |\:/) {
        const ags: Array<number> = json.split(sp).map((i) => +i);
        ags[1] && (ags[1] = +ags - 1);
        return new Time(...ags);
    }
    static dateConstructor(date: Date = new Date()) {
        return new Time(date.getTime());
    }

    get year() {
        return this.getFullYear();
    }
    get month() {
        return this.getMonth();
    }
    get day() {
        return this.getDate();
    }
    get hours() {
        return this.getHours();
    }
    get min() {
        return this.getMinutes();
    }
    get sec() {
        return this.getSeconds();
    }
    get timestamp() {
        return Math.floor(this.getTime() / 1e3);
    }

    format(isData: boolean = false) {
        let _sz: Function = supZero,
            date: any = [this.year, _sz(this.month + 1), _sz(this.day)];
        date = date.join('-');
        date += isData ? ' ' + [_sz(this.hours), _sz(this.min)].join(':') : '';
        return date;
    }

    toSqiString(h: string = '00:00:00') {
        return [this.year, _sp(this.month + 1), _sp(this.day)].join('-') + ' ' + h;
    }
}
function supZero(num: number) {
    return (num < 10 ? '0' : '') + num;
}

export default Time;
