import {
    Component,
    Inject,
    Model,
    ModelSync,
    PropSync,
    Prop,
    Vue,
    Watch,
    Emit
} from 'vue-property-decorator';
import SqiExtend from '@/api';
import { mixins } from 'vue-class-component';
import StroeAutoHook from './autoInitGetter';
import ViewButton from '@/components/ViewButton';
import ViewDialog from '@/components/ViewDialog';
import ViewTable from '@/components/ViewTable';
import ViewForm from '@/components/ViewForm/index.vue';
import ViewDialogForm from '@/components/ViewForm/ViewDialogForm.vue';
import ViewSearch from '@/components/ViewSearch';
import ViewCheckbox from '@/components/ViewForm/ViewFormCheckbox.vue';
import ServerEnum from '@/store/modules/global';
import { UserModule } from '@/store/modules/user';
import axios from 'axios';
import { Loading } from 'element-ui';
import ViewVideo from '@/components/ViewVideo';
import ViewFormList from '@/components/ViewForm/list.vue';
import filters from '@/utils/filters';
import utils from '@/utils';
import SelectBrand from '@/components/SelectBrand';
import EnumLabel from '@/constant/EnumLabel';

@Component({
    components: {
        VButton: ViewButton,
        VDialog: ViewDialog,
        VTable: ViewTable,
        VForm: ViewForm,
        VSearch: ViewSearch,
        VVideo: ViewVideo,
        VDialogForm: ViewDialogForm,
        VCheckbox: ViewCheckbox,
        VFormList: ViewFormList,
        SelectBrand
    },
    filters,
    directives: {
        role: {
            bind(el, binding) {
                let bv = binding.arg || binding.value,
                    roles = UserModule.permissions;
                if (!roles.includes(bv)) {
                    el.style.display = 'none';
                }
            },
            update(el, binding) {}
        }
    }
})
export default class VueView extends SqiExtend {
    get $filters() {
        return filters;
    }
    $hasRole(role: string) {
        return UserModule.permissions.includes(role);
    }
    get $axios() {
        return axios;
    }
    get $global() {
        return ServerEnum.global;
    }
    get $globalEnum() {
        return ServerEnum.gobaleEnum;
    }
    $back() {
        this.$router.back();
    }
    $getStatusClass(e: boolean) {
        return `font-${e ? 'finish' : 'wait'}`;
    }
    $copyText(msg: string) {
        utils.copyText(msg);
    }
    load: any = {};
    $showGobalLoad() {
        this.load = Loading.service({ boay: true });
    }
    $hideGobalLoad() {
        this.load.close();
    }
    $imgDown(downloadName: string, url: string) {
        var x = new XMLHttpRequest();
        x.open('GET', url, true);
        x.responseType = 'blob';
        x.onload = function (e) {
            var url = window.URL.createObjectURL(x.response);
            var a = document.createElement('a');
            a.href = url;
            a.download = '';
            a.click();
        };
        x.send();
    }
    activated() {
        this?.getList?.();
    }
    created() {
        console.log(this.$globalEnum);
    }
}

export { Component, Inject, Model, Prop, Vue, Watch, Emit, ModelSync, PropSync };
