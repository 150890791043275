import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'common/dict',
        method: 'get',
        name: 'getDict'
    },
    {
        url: 'manage/upload',
        method: 'post'
    }
];

const dict: Sqi = new Sqi(list);
export default dict;
