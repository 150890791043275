<template>
    <el-form
        :label-position="labelPosition"
        :label-width="isSearch ? '150px' : labelWidth"
        :class="{
            'double-row': isDoubleRow,
            'is-oper': formList.length > 4,
            'is-search': isSearch,
            'is-close': !searchOpenStatus
        }"
        ref="form"
        :inline="inline"
        :rules="rules"
        :model="edit"
        :validate-on-rule-change="false"
        :style="{ height: isSearch ? Math.ceil(formList.length / 4) * 46 + 'px' : 'auto' }"
    >
        <i
            :class="searchOpenStatus ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            @click="searchOpenStatus = !searchOpenStatus"
            v-if="isSearch && formList.length > 4"
        ></i>
        <div class="el-form-sub-tit" v-if="subTit || $slots.subTit">
            <template v-if="subTit">{{ subTit }}</template>
            <slot name="subTit" :edit="edit" v-else />
        </div>
        <template v-for="(i, k) of formList">
            <div
                class="el-form-sub-tit"
                v-if="i.subTit || $slots[i.subTitSite]"
                :key="k + 'sub-tit'"
            >
                <template v-if="i.subTit">{{ i.subTit }}</template>
                <slot :name="i.subTitSite" :edit="edit" v-else />
            </div>
            <el-form-item
                :key="k"
                v-if="!(i.type === FORM_ITEM_TYPE.HIDDEN || (i.isHidden && i.isHidden(edit)))"
                :label="i.label"
                :prop="i.ruleProp || i.prop"
                :class="[
                    i.type === FORM_ITEM_TYPE.TEXTAREA && 'form-item-textarea',
                    i.itemBind && i.itemBind.class
                ]"
                :style="getWidth(i)"
                v-bind="i.itemBind"
            >
                <!-- <template v-if="i.site">
                    <slot :name="i.site" :edit="edit" />
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.SHOW">
                    {{ i.value }}
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.SELECT">
                    <el-select
                        @change="i.event && i.event.change($event, edit)"
                        v-model="edit[i.prop || i.value]"
                        v-if="i.options"
                        v-bind="i.select"
                    >
                        <el-option v-for="item in i.options" :key="item.value" v-bind="item">
                        </el-option>
                    </el-select>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.BRAND">
                    <select-brand
                        v-bind="i.select"
                        v-model="edit[i.prop || i.value || i.type + k]"
                        :id.sync="edit[i.props ? i.props[0] : i.type + k + 'id']"
                        :name.sync="edit[i.props ? i.props[1] : i.type + k + 'name']"
                        @change="i.event && i.event.change($event, edit)"
                    ></select-brand>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.SELECT_CITY">
                    <select-city
                        v-bind="i.select"
                        v-model="edit[i.prop || i.value || i.type + k]"
                        :id.sync="edit[i.props ? i.props[0] : i.type + k + 'id']"
                        :name.sync="edit[i.props ? i.props[1] : i.type + k + 'name']"
                    ></select-city>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.CHANNEL">
                    <select-channel
                        v-bind="i.select"
                        v-model="edit[i.prop || i.value || i.type + k]"
                        :id.sync="edit[i.props ? i.props[0] : i.type + k + 'id']"
                        :name.sync="edit[i.props ? i.props[1] : i.type + k + 'name']"
                    ></select-channel>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.VIP_CARD">
                    <select-card
                        v-model="edit[i.prop || i.value || i.type + k]"
                        :brand="edit[i.brand] || i.brand"
                        :id.sync="edit[i.props ? i.props[0] : i.type + k + 'id']"
                        :name.sync="edit[i.props ? i.props[1] : i.type + k + 'name']"
                    ></select-card>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.DATE">
                    <el-date-picker
                        v-model="edit[i.prop || i.value]"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :placeholder="i.placeholder || '选择日期'"
                    >
                    </el-date-picker>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.TIME_RANGE">
                    <time-range :stime.sync="edit[i.props[0]]" :etime.sync="edit[i.props[1]]">
                    </time-range>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.NUM_RANGE">
                    <number-range
                        :snum.sync="edit[i.props ? i.props[0] : i.type + k + 'id']"
                        :num.sync="edit[i.props ? i.props[1] : i.type + k + 'id']"
                        :enum.sync="edit[i.props ? i.props[2] : i.type + k + 'id']"
                    >
                    </number-range>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.UPLOAD_IMG">
                    <el-upload
                        :action="$sqi.upload.url"
                        list-type="picture-card"
                        :data="{ group: i.group || 'COMMON_IMAGE' }"
                        :multiple="false"
                        :on-success="({ data }) => (edit[i.prop || i.value] = data)"
                        :show-file-list="false"
                    >
                        <template>
                            <div class="upload-img">
                                <i class="el-icon-plus" v-if="!edit[i.prop || i.value]"></i>
                                <i
                                    class="el-icon-video-camera"
                                    v-else-if="isMp4(edit[i.prop || i.value])"
                                ></i>
                                <img v-else :src="edit[i.prop || i.value]" alt="" />
                            </div>
                        </template>
                    </el-upload>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.VIP_ROOM">
                    <select-room v-model="edit[i.prop || i.value]"></select-room>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.CHECKBOX">
                    <view-form-checkbox v-model="edit[i.prop || i.value]" v-bind="i">
                    </view-form-checkbox>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.SELECT_SHOP">
                    <select-shop
                        v-bind="i"
                        v-model="edit[i.props[1]]"
                        :brand.sync="edit[i.props[0]]"
                        @org-change="i.event && i.event.change($event, edit)"
                    >
                    </select-shop>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.DATE_TIME_LIST">
                    <view-date-time-list
                        v-bind="i"
                        v-model="edit[i.prop || i.value]"
                    ></view-date-time-list>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.BRAND_VIP">
                    <select-brand-vip
                        v-model="edit[i.porp || i.value || 'select-brand-vip-model-' + k]"
                        :brand-id.sync="
                            edit[typeof i.props[0] === 'string' ? i.props[0] : i.props[0][0]]
                        "
                        :vip-id.sync="
                            edit[typeof i.props[1] === 'string' ? i.props[1] : i.props[1][0]]
                        "
                        :brand-name.sync="
                            edit[
                                typeof i.props[0] === 'string'
                                    ? 'select-brand-vip-brand-' + k
                                    : i.props[0][1]
                            ]
                        "
                        :vip-name.sync="
                            edit[
                                typeof i.props[1] === 'string'
                                    ? 'select-brand-vip-vip-' + k
                                    : i.props[1][1]
                            ]
                        "
                    ></select-brand-vip>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.DATE_RANGE">
                    <el-date-picker
                        v-model="edit[i.prop || i.value]"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.DATE_RANGE_DOUBLE">
                    <date-range-double
                        :stime.sync="edit[i.props[0]]"
                        :etime.sync="edit[i.props[1]]"
                        v-bind="i.bind || {}"
                    >
                    </date-range-double>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.NUMBER">
                    <el-input v-bind="i" v-model="edit[i.prop || i.value]">
                        <template #append v-if="i.unit">{{ i.unit }}</template>
                    </el-input>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.ORG_TREE">
                    <select-org-tree v-model="edit[i.prop]"> </select-org-tree>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.BRAND_COUPON">
                    <select-brand-coupon
                        v-model="edit[i.prop || i.type + k + 'model']"
                        :brand-id.sync="edit[i.props[0][0] || i.type + k + '-brandId']"
                        :brand-name.sync="edit[i.props[0][1] || i.type + k + '-brandName']"
                        :coupon-id.sync="edit[i.props[1][0] || i.type + k + '-couponId']"
                        :coupon-name.sync="edit[i.props[1][1] || i.type + k + '-couponName']"
                    >
                    </select-brand-coupon>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.RICH_TEXT">
                    <rich-text v-model="edit[i.prop || i.type + k + 'model']"> </rich-text>
                </template>
                <template v-else-if="i.type === FORM_ITEM_TYPE.EMPTY"> </template>

                <template v-else-if="i.type !== FORM_ITEM_TYPE.HIDDEN">
                    <el-input v-bind="i" v-model="edit[i.prop || i.value]">
                        <template #append v-if="i.unit">{{ i.unit }}</template>
                    </el-input>
                </template> -->
                <view-form-value :edit="edit" :config="i">
                    <slot :name="i.site" :edit="edit" />
                </view-form-value>
            </el-form-item>
        </template>
    </el-form>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        PropSync,
        Watch,
        Emit,
        ModelSync
    } from 'vue-property-decorator';
    import FormStructure from '@/utils/FormStructure';
    import userManage from '@/api/userManage';
    import SqiExtend from '@/api';
    import Time from '@/utils/Time';
    import SelectBrand from '@/components/SelectBrand';
    import SelectChannel from '@/components/SelectChannel';
    import SelectCard from '@/components/SelectCard';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import SelectRoom from '@/components/SelectRoom';
    import SelectShop from '@/components/SelectShop';
    import DateRangeDouble from '@/components/DateRangeDouble';
    import ViewFormCheckbox from './ViewFormCheckbox';
    import SelectBrandVip from '../BrandVip';
    import SelectCity from '../SelectCity';
    import SelectOrgTree from '../OrgTree';
    import TimeRange from '../TimeRange';
    import SelectBrandCoupon from '../BrandCoupon';
    import RichText from '../RichText';
    import ViewDateTimeList from '../ViewDateTimeList';
    import NumberRange from '../NumberRange';
    import ViewFormValue from './item.vue';
    import rules from './rules';
    @Component({
        name: 'ViewForm',
        components: {
            SelectBrand,
            SelectRoom,
            SelectCard,
            SelectShop,
            DateRangeDouble,
            ViewFormCheckbox,
            SelectBrandVip,
            SelectOrgTree,
            TimeRange,
            SelectBrandCoupon,
            SelectChannel,
            RichText,
            ViewDateTimeList,
            SelectCity,
            ViewFormValue: ViewFormValue,
            NumberRange
        }
    })
    export default class ViewForm extends SqiExtend {
        @ModelSync('value', 'change') val: any;
        @Prop() formList: any;
        @Prop() init: any;
        @Prop({ default: false, type: Boolean }) isDoubleRow?: boolean;
        @Prop({ type: Function }) dataHandle?: any;
        @Prop({ default: '100px' }) labelWidth?: any;
        @Prop({ default: false }) inline?: boolean;
        @Prop({ default: 'right' }) labelPosition?: string;
        @Prop({ default: false, type: Boolean }) isFormInit: boolean | undefined;
        @Prop({ default: true, type: Boolean }) isInit: boolean | undefined;
        @Prop({ default: false, type: Boolean }) isReload: boolean | undefined;
        @Prop({ default: '', type: String }) subTit: any;
        @Prop({ default: '45%', type: String }) itemWidth: any;
        @Prop({ default: false, type: Boolean }) isSearch: boolean | undefined;
        @Prop({ default: false, type: Boolean }) notRequired: boolean | undefined;

        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        edit: any = {};
        form: FormStructure | any = {};
        widt100Type = [
            FORM_ITEM_TYPE.TEXTAREA,
            FORM_ITEM_TYPE.CHECKBOX,
            FORM_ITEM_TYPE.RICH_TEXT,
            FORM_ITEM_TYPE.MARKDOWN,
            FORM_ITEM_TYPE.DATE_TIME_LIST
        ];
        // get form() {
        // 	return new FormStructure(this.formList.filter((i: any) => i.prop));
        // }
        // get edit() {
        // 	return this.form.edit;
        // }
        getWidth(i) {
            return this.inline
                ? {}
                : {
                      width: i.width
                          ? i.width
                          : this.widt100Type.includes(i.type)
                          ? '100%'
                          : this.itemWidth
                  };
        }

        get rules() {
            const { formList, edit, isSearch, notRequired } = this;
            return Object.assign(
                {},
                ...formList
                    .filter((i) => i.rules || i.prop || i.ruleProp)
                    .map((i) => ({
                        [i.prop || i.ruleProp]: i.rules
                            ? i.rules.map((j) => {
                                  if (typeof j == 'string') {
                                      return rules[j];
                                  } else {
                                      if (typeof j.rule === 'string') {
                                          return rules[j.rule](edit, j.params);
                                      } else if (typeof j.rule === 'function') {
                                          return j.rule(edit, j.params);
                                      } else {
                                          console.error('未找到改规则');
                                      }
                                  }
                              })
                            : [
                                  isSearch || notRequired || i.notRequire
                                      ? { required: false }
                                      : rules.require
                              ]
                    }))
            );
        }

        async clearVal() {
            await this.$nextTick((v) => {
                this.$refs?.form?.clearValidate();
                this.$refs?.form && this.form.setRef?.(this.$refs.form);
            });
        }

        get key() {
            return Date.now() + '-form';
        }

        get formProps() {
            return this.formList.map((i: any) => i.props || [i.prop]).flat(2);
        }

        @Watch('formProps', { immediate: true })
        judgeProps(n: (string | undefined)[], o: (string | undefined)[]) {
            if (n?.sort().join('') !== o?.sort().join('') && n.length) {
                this.initForm();
            }
        }

        @Watch('init')
        initForm() {
            console.log('init', this.key);
            const { init } = this;
            if (init) {
                this.form = this.getForm().setData(init);
            } else {
                this.form = this.getForm();
            }
            this.edit = this.form.edit;
            this.val = this.form;
            this.clearVal();
        }

        getForm() {
            let form = new FormStructure(FormStructure.viewFormConfigToFormConfig(this.formList));
            return form;
        }

        searchOpenStatus: boolean = false;

        date: string = new Time().format(true);

        beforeDestroy() {}

        created() {
            // if (this.val.constructor.name === 'FormStructure') {
            //     console.log('created', this.key);
            //     this.form = this.val;
            //     this.edit = this.form.edit;
            //     this.clearVal();
            // }
        }
    }
</script>
<style lang="scss">
    .el-form-sub-tit {
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
        margin-bottom: 20px;
    }
</style>
<style lang="scss" scoped>
    .double-row {
        @include flex-layout(flex-start);
        flex-wrap: wrap;
        .el-form-item {
            width: 45%;
            flex-shrink: 0;
        }

        .form-item-textarea {
            width: 100%;
        }
    }
    .is-search {
        &.is-oper {
            padding-right: 50px;
            position: relative;
            transition: all 0.3s;
            overflow-y: hidden;
            width: 100% !important;
            flex-shrink: 0;
            flex-grow: 1;
        }
        &.is-close {
            height: 42px !important;
        }
        & > i {
            position: absolute;
            font-size: 20px;
            right: 0;
            top: 5px;
            color: #aaa;
            cursor: pointer;
        }
        .el-form-item {
            width: calc(25% - 10px);
            min-width: 396px;
        }
    }
    .vf-sub {
        width: 100%;
        text-align: center;
    }
    .vf-head {
        width: 100%;
        height: 50px;
        @include flex-layout;
        color: #343434;
        font-weight: bold;
        font-size: 18px;
    }
    .upload-img {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .el-select,
    .el-cascader {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
</style>
