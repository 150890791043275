<template>
    <div
        class="view-form-val"
        :class="
            [FORM_ITEM_TYPE.INPUTS, FORM_ITEM_TYPE.TIME_RANGE].includes(config.type) && 'vfv-flex'
        "
    >
        <template v-if="config.site">
            <slot name="default" :edit="edit" />
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.SHOW">
            {{ edit[config.prop] || config.value }}
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.SELECT">
            <el-select
                clearable
                @change="config.event && config.event.change($event, edit)"
                v-model="edit[config.prop || config.value]"
                v-if="config.options"
                v-bind="config.select || config.bind"
                filterable
            >
                <el-option v-for="item in config.options" :key="item.value" v-bind="item">
                </el-option>
                <template #prefix v-if="config.tip">
                    <el-popover
                        placement="top-center"
                        title="提示"
                        width="200"
                        trigger="hover"
                        :content="config.tip"
                    >
                        <i slot="reference" class="el-icon-warning-outline"></i>
                    </el-popover>
                </template>
            </el-select>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.CASCADER">
            <el-cascader
                v-bind="config.bind"
                filterable
                @change="config.event && config.event.change($event, edit)"
                v-model="edit[config.prop || config.value]"
                :options="config.options"
                :disabled="config.disabled"
            >
            </el-cascader>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.BRAND">
            <select-brand
                v-bind="config.select"
                v-model="edit[config.prop || config.value || config.type + key]"
                :id.sync="edit[config.props ? config.props[0] : config.type + key + 'id']"
                :name.sync="edit[config.props ? config.props[1] : config.type + key + 'name']"
                @change="config.event && config.event.change($event, edit)"
            ></select-brand>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.SELECT_CITY">
            <select-city
                v-bind="config.select"
                v-model="edit[config.prop || config.value || config.type + key]"
                :id.sync="edit[config.props ? config.props[0] : config.type + key + 'id']"
                :name.sync="edit[config.props ? config.props[1] : config.type + key + 'name']"
            ></select-city>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.CHANNEL">
            <select-channel
                v-bind="config.select"
                v-model="edit[config.prop || config.value || config.type + key]"
                :id.sync="edit[config.props ? config.props[0] : config.type + key + 'id']"
                :name.sync="edit[config.props ? config.props[1] : config.type + key + 'name']"
            ></select-channel>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.VIP_CARD">
            <select-card
                v-model="edit[config.prop || config.value || config.type + key]"
                :brand="edit[config.brand] || config.brand"
                :id.sync="edit[config.props ? config.props[0] : config.type + key + 'id']"
                :name.sync="edit[config.props ? config.props[1] : config.type + key + 'name']"
            ></select-card>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.DATE">
            <el-date-picker
                v-model="edit[config.prop || config.value]"
                v-bind="Object.assign({ type: 'date', 'value-format': 'yyyy-MM-dd' }, config.bind)"
                :placeholder="config.placeholder || '选择日期'"
            >
            </el-date-picker>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.TIME_RANGE">
            <el-time-picker
                v-model="edit[config.props[0]]"
                value-format="HH:mm:ss"
                placeholder="开始时间"
            >
            </el-time-picker>
            <el-time-picker
                v-model="edit[config.props[1]]"
                value-format="HH:mm:ss"
                placeholder="结束时间"
            >
            </el-time-picker>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.TIME">
            <el-time-picker
                v-model="edit[config.prop || config.value]"
                v-bind="Object.assign({ type: 'date', 'value-format': 'yyyy-MM-dd' }, config.bind)"
                value-format="HH:mm:ss"
                :placeholder="config.placeholder"
            >
            </el-time-picker>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.NUM_RANGE">
            <number-range
                :snum.sync="edit[config.props ? config.props[0] : config.type + key + 'id']"
                :num.sync="edit[config.props ? config.props[1] : config.type + key + 'id']"
                :enum.sync="edit[config.props ? config.props[2] : config.type + key + 'id']"
            >
            </number-range>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.INPUTS">
            <template v-for="(i, k) of config.props">
                <el-input
                    :key="k"
                    v-model="edit[config.props ? config.props[k] : config.type + key + 'id']"
                >
                    <template #append v-if="config.unit">{{ config.unit }}</template>
                </el-input>
                <span v-if="config.props.length - 1 !== k" :key="k + '-spec'">{{
                    config.spec
                }}</span>
            </template>
        </template>

        <template v-else-if="config.type === FORM_ITEM_TYPE.UPLOAD">
            <el-upload
                :action="$sqi.upload.url"
                :multiple="false"
                :data="config.data || { type: 'pdf' }"
                :show-file-list="false"
                :on-success="
                    (res) => {
                        let { data } = res;
                        if (config.event && config.event.success) {
                            config.event.success(data, res, edit);
                        } else {
                            edit[config.prop || config.value] = data;
                        }

                        loading = false;
                    }
                "
            >
                <el-button type="primary" plain v-loading="loading">{{
                    config.label || '点击上传'
                }}</el-button>
            </el-upload>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.UPLOAD_IMG">
            <el-upload
                :action="fileData.postURL || config.upURI || $sqi.upload.url"
                list-type="picture-card"
                :data="config.data || fileData || { type: 'img' }"
                :multiple="false"
                :disabled="config.disabled"
                :on-success="
                    (res) => {
                        let { data } = res;
                        if (config.event && config.event.success) {
                            config.event.success(data, res, edit);
                        } else {
                            edit[config.prop || config.value] = data;
                        }

                        loading = false;
                    }
                "
                :show-file-list="false"
            >
                <template>
                    <div class="upload-img" v-loading="loading">
                        <i class="el-icon-remove-outline" v-if="config.disabled"></i>
                        <i class="el-icon-plus" v-else-if="!edit[config.prop || config.value]"></i>
                        <i
                            class="el-icon-video-camera"
                            v-else-if="isMp4(edit[config.prop || config.value])"
                        ></i>
                        <img v-else :src="edit[config.prop || config.value]" alt="" />
                        <i
                            class="el-icon-delete"
                            @click.stop="edit[config.prop || config.value] = ''"
                            v-if="edit[config.prop || config.value] && config.del"
                        ></i>
                    </div>
                </template>
                <div slot="tip" class="el-upload__tip">{{ config.tip || '建议尺寸500*500' }}</div>
            </el-upload>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.UPLOAD_IMG_LIST">
            <el-upload
                :action="config.upURI || $sqi.upload.url"
                list-type="picture-card"
                :data="{ group: config.group || 'COMMON_IMAGE' }"
                :multiple="true"
                :file-list="fileList"
                :on-success="upSuccess"
                :before-upload="sload"
                :on-remove="removeFile"
            >
                <template>
                    <div class="upload-img" v-loading="loading">
                        <i class="el-icon-plus"></i>
                        <!-- <img v-else :src="edit[config.prop || config.value]" alt="" /> -->
                    </div>
                </template>
                <div slot="tip" class="el-upload__tip">{{ config.tip || '建议尺寸500*500' }}</div>
            </el-upload>
        </template>

        <template v-else-if="config.type === FORM_ITEM_TYPE.VIP_ROOM">
            <select-room v-model="edit[config.prop || config.value]"></select-room>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.CHECKBOX">
            <view-form-checkbox v-model="edit[config.prop || config.value]" v-bind="config">
            </view-form-checkbox>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.SELECT_SHOP">
            <select-shop
                v-bind="config"
                v-model="edit[config.props[1]]"
                :brand.sync="edit[config.props[0]]"
                @org-change="config.event && config.event.change($event, edit)"
            >
            </select-shop>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.DATE_TIME_LIST">
            <view-date-time-list
                v-bind="config"
                v-model="edit[config.prop || config.value]"
            ></view-date-time-list>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.MARKDOWN">
            <markdown
                v-bind="config.bind"
                v-model="edit[config.prop || config.type + key + 'model']"
            ></markdown>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.BRAND_VIP">
            <select-brand-vip
                v-model="edit[config.porp || config.value || 'select-brand-vip-model-' + key]"
                :brand-id.sync="
                    edit[typeof config.props[0] === 'string' ? config.props[0] : config.props[0][0]]
                "
                :vip-id.sync="
                    edit[typeof config.props[1] === 'string' ? config.props[1] : config.props[1][0]]
                "
                :brand-name.sync="
                    edit[
                        typeof config.props[0] === 'string'
                            ? 'select-brand-vip-brand-' + key
                            : config.props[0][1]
                    ]
                "
                :vip-name.sync="
                    edit[
                        typeof config.props[1] === 'string'
                            ? 'select-brand-vip-vip-' + key
                            : config.props[1][1]
                    ]
                "
            ></select-brand-vip>
        </template>
        <template v-else-if="[FORM_ITEM_TYPE.DATE_RANGE].includes(config.type)">
            <el-date-picker
                v-model="edit[config.prop || config.value]"
                :type="config.bind ? config.bind.type || 'daterange' : 'daterange'"
                :value-format="(config.bind ? config.bind.valueFormat : '') || 'yyyy-MM-dd'"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                v-bind="config.bind"
            >
            </el-date-picker>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.DATE_RANGE_DOUBLE">
            <date-range-double
                :stime.sync="edit[config.props[0]]"
                :etime.sync="edit[config.props[1]]"
                v-bind="config.bind || {}"
            >
            </date-range-double>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.RADIO">
            <el-radio
                v-for="(i, k) of config.options"
                :key="k"
                v-model="edit[config.prop]"
                v-bind="config.bind || {}"
                :label="i.value"
            >
                {{ i.label }}
            </el-radio>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.NUMBER">
            <el-input v-bind="config" v-model="edit[config.prop || config.value]">
                <template #append v-if="config.unit">{{ config.unit }}</template>
                <template #suffix v-if="config.tip">
                    <el-popover
                        placement="top-center"
                        title="提示"
                        width="200"
                        trigger="hover"
                        :content="config.tip"
                    >
                        <i slot="reference" class="el-icon-warning-outline"></i>
                    </el-popover>
                </template>
            </el-input>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.ORG_TREE">
            <select-org-tree v-model="edit[config.prop]"> </select-org-tree>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.BRAND_COUPON">
            <select-brand-coupon
                v-model="edit[config.prop || config.type + key + 'model']"
                :brand-id.sync="edit[config.props[0][0] || config.type + key + '-brandId']"
                :brand-name.sync="edit[config.props[0][1] || config.type + key + '-brandName']"
                :coupon-id.sync="edit[config.props[1][0] || config.type + key + '-couponId']"
                :coupon-name.sync="edit[config.props[1][1] || config.type + key + '-couponName']"
                v-bind="config.bind || {}"
            >
            </select-brand-coupon>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.RICH_TEXT">
            <rich-text v-model="edit[config.prop || config.type + key + 'model']"> </rich-text>
        </template>
        <template v-else-if="config.type === FORM_ITEM_TYPE.EMPTY"> </template>

        <template v-else-if="config.type !== FORM_ITEM_TYPE.HIDDEN">
            <el-input
                v-bind="config"
                @change="config.event && config.event.change($event, edit)"
                v-model="edit[config.prop || config.value]"
            >
                <el-button
                    @click="getCom32"
                    slot="append"
                    icon="el-icon-refresh-right"
                    v-if="config.r32"
                ></el-button>
                <template #append v-if="config.unit">{{ config.unit }}</template>
                <template #suffix v-if="config.tip">
                    <el-popover
                        placement="top-center"
                        title="提示"
                        width="200"
                        trigger="hover"
                        :content="config.tip"
                    >
                        <i slot="reference" class="el-icon-warning-outline"></i>
                    </el-popover>
                </template>
            </el-input>
        </template>
    </div>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        PropSync,
        Watch,
        Emit,
        ModelSync
    } from 'vue-property-decorator';
    import FormStructure from '@/utils/FormStructure';
    import userManage from '@/api/userManage';
    import SqiExtend from '@/api';
    import Time from '@/utils/Time';
    import SelectBrand from '@/components/SelectBrand';
    import SelectChannel from '@/components/SelectChannel';
    import SelectCard from '@/components/SelectCard';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import SelectRoom from '@/components/SelectRoom';
    import SelectShop from '@/components/SelectShop';
    import DateRangeDouble from '@/components/DateRangeDouble';
    import ViewFormCheckbox from './ViewFormCheckbox';
    import SelectBrandVip from '../BrandVip';
    import SelectCity from '../SelectCity';
    import SelectOrgTree from '../OrgTree';
    import TimeRange from '../TimeRange';
    import SelectBrandCoupon from '../BrandCoupon';
    import RichText from '../RichText';
    import ViewDateTimeList from '../ViewDateTimeList';
    import NumberRange from '../NumberRange';
    import Markdown from '../Markdown/index.vue';

    @Component({
        name: 'ViewForm',
        components: {
            SelectBrand,
            SelectRoom,
            SelectCard,
            SelectShop,
            DateRangeDouble,
            ViewFormCheckbox,
            SelectBrandVip,
            SelectOrgTree,
            TimeRange,
            SelectBrandCoupon,
            SelectChannel,
            RichText,
            ViewDateTimeList,
            SelectCity,
            Markdown,
            NumberRange
        }
    })
    export default class ViewForm extends SqiExtend {
        @ModelSync('value', 'change') val: any;

        @Prop({ default: () => {} }) config: any;
        @Prop({ default: () => {} }) edit: any;
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;

        get key() {
            return Date.now() + '-' + parseInt(Math.random() * 1e5 + '') + '-form';
        }

        fileData: any = { type: 'img' };
        getSign(file) {
            if (this.config.upURI) {
                return;
            }
            this.loading = true;
            return this.$sqi
                .getUploadSign({ filePath: file.name, group: this.config.group || 'AD_IMAGE' })
                .then((r: { data: any }) => {
                    this.fileData = Object.assign(
                        { group: this.config.group || 'AD_IMAGE' },
                        r.data
                    );
                });
        }

        loading: boolean = false;
        uploadSuccess(e) {
            this.loading = false;
            console.log(e);
        }
        sload() {
            this.loading = true;
        }
        isMp4(src: string) {
            return /\.mp4$/.test(src);
        }

        get fileList() {
            const config = this.config;
            const list = this.edit[config.prop || config.value] || [];
            return list.map((i) => ({ name: '', url: i }));
        }

        getCom32() {
            this.$sqi.randomSign32().then((res) => {
                this.edit[this.config.prop || this.config.value] = res.data;
            });
        }

        upSuccess({ data }, file, fileList) {
            const config = this.config;
            this.loading = false;
            this.edit[config.prop || config.value] = this.edit[config.prop || config.value] || [];
            this.edit[config.prop || config.value].push(data);
        }
        removeFile(e, fs, fileList) {
            const config = this.config;
            this.edit[config.prop || config.value] = fs.map((i) => i.url);
            console.log(e, f, fileList);
        }

        beforeDestroy() {}

        created() {}
    }
</script>
<style lang="scss">
    .el-form-sub-tit {
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
        color: #333;
        margin-bottom: 20px;
    }
</style>
<style lang="scss" scoped>
    i.el-icon-warning-outline {
        color: #e6a23c;
        cursor: pointer;
    }
    .double-row {
        @include flex-layout(flex-start);
        flex-wrap: wrap;
        .el-form-item {
            width: 45%;
            flex-shrink: 0;
        }

        .form-item-textarea {
            width: 100%;
        }
    }
    .is-search {
        &.is-oper {
            padding-right: 50px;
            position: relative;
            transition: all 0.3s;
            overflow-y: hidden;
            width: 100% !important;
            flex-shrink: 0;
            flex-grow: 1;
        }
        &.is-close {
            height: 62px !important;
        }
        & > i {
            position: absolute;
            font-size: 30px;
            right: 0;
            top: 5px;
            color: #aaa;
            cursor: pointer;
        }
        .el-form-item {
            width: calc(25% - 10px);
            min-width: 396px;
        }
    }
    .vf-sub {
        width: 100%;
        text-align: center;
    }
    .vf-head {
        width: 100%;
        height: 50px;
        @include flex-layout;
        color: #343434;
        font-weight: bold;
        font-size: 18px;
    }
    .upload-img {
        width: 100%;
        height: 100%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .el-icon-delete {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #f56c6c;
            z-index: 9999;
        }
    }
    .el-select,
    .el-cascader {
        width: 100%;
        max-width: 500px;
    }
    .el-date-editor {
        width: 100%;
    }
    .vfv-flex {
        @include flex-layout;
        span {
            padding: 0 20px;
        }
        .el-input {
        }
    }
</style>
