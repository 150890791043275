import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/page/list',
        method: 'get',
        name: 'getPageList'
    },
    {
        url: 'manage/page/add',
        method: 'post',
        name: 'addPage'
    },
    {
        url: 'manage/page/edit',
        method: 'post',
        name: 'editPage'
    },
    {
        url: 'manage/page/del',
        method: 'delete',
        name: 'delPage'
    },

    {
        url: 'manage/page/details',
        method: 'get',
        name: 'getPage'
    },
    {
        url: 'common/pay_notify',
        method: 'post'
    },
    {
        url: 'manage/config/list',
        method: 'get',
        name: 'getConfigList'
    },

    {
        url: 'manage/config/edit',
        method: 'post',
        name: 'editConfig'
    },
    {
        url: 'common/sfRoutePush',
        method: 'post',
        name: 'sfRoutePush'
    }
];

const ge: Sqi = new Sqi(list);
export default ge;
