import { Message } from 'element-ui';
const utils = {
    copyText(msg) {
        var input = document.createElement('input');
        input.value = msg;
        document.body.appendChild(input);
        input.select();
        document.execCommand('Copy'); // 执行复制操作
        document.body.removeChild(input);
        Message.info('复制成功');
    },
    getNumberRangeList(sn, en) {
        let arr = [];
        for (; sn <= en; sn++) {
            arr.push(sn);
        }
        return arr;
    }
};
export default utils;
