<template>
    <el-time-picker
        v-model="val"
        :value-format="valueFormat"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        is-range
        :selectableRange="'00:00:00-23:59:59'"
    >
    </el-time-picker>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        PropSync
    } from 'vue-property-decorator';

    @Component({
        name: 'TimeRange',
        components: {}
    })
    export default class TimeRange extends Vue {
        @PropSync('stime', { type: String }) _stime: any;
        @PropSync('etime', { type: String }) _etime: any;
        @Prop({ type: String, default: 'HH:mm:ss' }) valueFormat: any;
        vas: any = [];
        get val() {
            return [this._stime || '00:00:00', this._etime || '00:00:00'];
        }
        set val(time) {
            this._stime = time[0];
            this._etime = time[1];
        }

        // @Watch('_stime', { immediate: true })
        // @Watch('_etime', { immediate: true })
        // isNull() {
        // 	console.log(this._stime, this._etime);
        // 	if (this._stime == null) {
        // 		this._stime = '00:00:00';
        // 	}
        // 	if (this._etime == null) {
        // 		this._etime = '00:00:00';
        // 	}
        // }
    }
</script>
<style lang="scss" scoped>
    .el-dialog__header {
    }
    .el-dialog__body {
        padding-top: 0;
    }
</style>
