<template>
    <el-select v-model="val" filterable :multiple="multiple" collapse-tags :disabled="disabled">
        <el-option v-for="item in brandList" :key="item.value" v-bind="item"> </el-option>
    </el-select>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import ServerEnum from '@/store/modules/global';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    @Component({
        name: 'SelectBrand'
    })
    export default class SelectBrand extends SqiExtend {
        @Model('change') value: any;
        get val() {
            return this.value ?? this._id;
        }
        @Prop({ default: false, type: Boolean }) auto?: boolean;
        set val(n) {
            this.$emit('change', n);
            this._id = n;
            this._name = this.listEnum.VALUE_LABEL[n];
        }
        @Prop({ default: false }) multiple?: boolean;
        @Prop({ default: false }) disabled?: boolean;

        @PropSync('id') _id: any;
        @PropSync('name') _name: any;
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        get listEnum() {
            return new EnumLabel(this.brandList);
        }
        get brandList() {
            return ServerEnum.brand;
        }
        @Watch('brandList', { immediate: true })
        setDefault(n) {
            if (this.auto && n.length) {
                this.val = this.brandList[0]?.value;
            }
        }

        created() {
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
