<template>
    <div class="form-checkbox">
        <el-checkbox @change="changeAll" v-if="isAll" v-model="vals">全选</el-checkbox>

        <el-checkbox-group :max="max" v-model="val">
            <el-checkbox v-for="i in options" :label="i.value" :key="i.value">{{
                i.label
            }}</el-checkbox>
        </el-checkbox-group>
        <span v-if="!options || !options.length">
            <slot name="empty">
                可选项为空
            </slot>
        </span>
    </div>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    @Component({
        name: 'FormCheckbox'
    })
    export default class FormCheckbox extends SqiExtend {
        @ModelSync('value', 'change', { default: () => [] }) val: any;
        @Prop({ default: 1, type: Number }) max?: number;
        @Prop({ default: false, type: Boolean }) isAll?: boolean;
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        @Prop({ default: () => [] }) options: any;

        get vals() {
            return this.val.length === this.options.length;
        }

        set vals(e) {
            console.log(e);
            if (e) {
                this.val = this.options.map((i) => i.value);
            } else {
                this.val = [];
            }
        }
        created() {}
    }
</script>
<style lang="scss" scoped>
    .form-checkbox {
        width: 100%;
        @include flex-layout(flex-start);
        flex-wrap: wrap;
    }
</style>
