<template>
    <el-cascader
        :options="list"
        filterable
        :disabled="disabled"
        :placeholder="[_brandName, _couponName].filter((i) => i).join('/')"
        v-model="val"
    >
    </el-cascader>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import global from '@/store/modules/global';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    @Component({
        name: 'SelectBrandCoupon'
    })
    export default class SelectBrandCoupon extends SqiExtend {
        @Model('change') value: any;
        get val() {
            if (this.value) {
                return this.value;
            } else {
                if (this._brandId) {
                    if (this._couponId) {
                        return [this._brandId, this._couponId];
                    } else {
                        return [this._brandId];
                    }
                } else {
                    return [];
                }
            }
        }
        set val(n) {
            this.$emit('change', n);
            this._brandId = n[0] ?? '';
            this._couponId = n[1] ?? '';
        }

        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        @PropSync('brandId') _brandId: any;
        @PropSync('brandName') _brandName: any;
        @PropSync('couponId') _couponId: any;
        @PropSync('couponName') _couponName: any;
        @Prop({ type: Boolean, default: false }) disabled?: boolean;

        @Watch('_brandId', { immediate: true })
        brandChange(n) {
            this._brandName = new EnumLabel(global.brandCoupon).VALUE_LABEL[n] ?? '';
        }

        @Watch('_couponId', { immediate: true })
        cchange(n) {
            for (let i of global.brandCoupon) {
                if (i.value === this._brandId) {
                    for (let j of i.children) {
                        if (j.value === this._couponId) {
                            this._couponName = j.label;
                            return;
                        }
                    }
                }
            }
        }

        couponList: any = [];

        get props() {
            return {
                lazy: true,
                lazyLoad: this.getCouponByBrand
            };
        }

        get list() {
            return global.brandCoupon;
        }

        async created() {
            if (global.brandCoupon) {
                await global.GET_BRAND_COUPON();
            }
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
