<template>
	<v-dialog
		:close-on-click-modal="false"
		:visible.sync="vis"
		:width="width"
		:title="title || $route.meta.title + '编辑'"
	>
		<!-- <div class="vf-head">
			<!-- <span>操作人：{{$store.state.user.name}}</span>
			<span>操作时间:{{date}}</span> 
		</div> -->
		<v-form v-model="form" v-bind="$props">
			<template v-for="(k, i) of $scopedSlots" v-slot:[i]="{ edit }">
				<slot :name="i" :edit="edit"></slot>
			</template>
		</v-form>

		<div class="vf-sub">
			<el-button @click="sub" :loading="load" type="primary">完成</el-button>
		</div>
	</v-dialog>
</template>
<script lang="ts">
	import {
		Component,
		Inject,
		Model,
		Prop,
		Vue,
		PropSync,
		ModelSync,
		Watch,
		Emit
	} from 'vue-property-decorator';
	import VDialog from '../ViewDialog';
	import FormStructure from '@/utils/FormStructure';
	import userManage from '@/api/userManage';
	import SqiExtend from '@/api';
	import Time from '@/utils/Time';
	import ViewForm from './index.vue';

	@Component({
		name: 'ViewDialogForm',
		components: {
			VDialog,
			VForm: ViewForm
		}
	})
	export default class ViewDialogForm extends SqiExtend {
		@PropSync('visible', { default: false }) vis!: boolean;
		@Prop() formList: any;
		@Prop() api: any;
		@Prop() init: any;
		@Prop({ default: false, type: Boolean }) isDoubleRow?: boolean;
		@Prop({ type: Function }) dataHandle?: any;
		@Prop({ default: '120px' }) labelWidth?: any;
		@Prop({ default: '' }) title?: string;
		@Prop({ default: '750px' }) width?: string;
		@Prop({ default: false, type: Boolean }) notRequired: boolean | undefined;

		form: any = {};
		@Prop({ default: false, type: Boolean }) isFormInit: boolean | undefined;

		load: boolean = false;
		sub() {
			console.log(111);
			let data = this.form?.getFormData?.();
			this.dataHandle && (data = this.dataHandle(data));
			this.$emit('sub', data);
			if (!this.api) {
				return;
			}

			this.load = true;
			this.api(data)
				.then(({ success, message, data }) => {
					this.load = false;
					this.$message[success ? 'success' : 'error'](message);
					if (success) {
						this.$emit('success', data);
						this.vis = false;
					}
				})
				.catch((e) => {
					this.load = false;
				});
		}

		@Watch('form')
		formChange() {
			this.$emit('form-change', this.form);
		}

		date: string = new Time().format(true);

		@Watch('vis')
		showChange(n: boolean) {
			if (n) {
				this.date = new Time().format(true);

				this.openTimer();
				if (this.init) {
					this.form.setData?.(this.init);
				} else {
					this.form.initData?.();
				}
				this.form?.formRef?.clearValidate?.();
			} else {
				this.closeTimer();
			}
		}

		timer: any = 0;
		openTimer() {
			this.timer = setInterval(() => {
				this.date = new Time().format(true);
			}, 3e4);
		}
		closeTimer() {
			clearInterval(this.timer);
		}

		created() {
			this.$nextTick(() => {
				console.log(this.$slots);
			});
		}
	}
</script>
<style lang="scss" scoped>
	.double-row {
		@include flex-layout;
		flex-wrap: wrap;
		.el-form-item {
			width: 45%;
			flex-shrink: 0;
		}

		.form-item-textarea {
			width: 100%;
		}
	}

	.vf-sub {
		width: 100%;
		text-align: center;
	}
	.vf-head {
		width: 100%;
		height: 50px;
		@include flex-layout;
		color: #343434;
		font-weight: bold;
		font-size: 18px;
	}
	.upload-img {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.el-date-editor {
		width: 100%;
	}
</style>
