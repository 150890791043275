import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'relation/list',
        method: 'get',
        name: 'getRelationListByShop'
    },
    {
        url: 'relation/process',
        method: 'post',
        name: 'importRelation'
    },
    {
        url: 'relation/group',
        method: 'get',
        name: 'exportRelationOtherList'
    },
    {
        url: 'relation/process_other',
        method: 'get',
        name: 'exectRelationOtherData'
    }
];

export { list as commonConfig };
const common: Sqi = new Sqi(list);
export default common;
