import Vue from 'Vue';

export interface formStructureItem {
    key?: string;
    prop?: string;
    value?: string;
    default?: any;
    handle?: Function;
    isCompute?: boolean;
}
export interface viewFormConfig {
    key?: string;
    prop?: string;
    props?: any[];
    value?: string;
    label: string;
    type: string;
    handle: Function;
    options?: any[];
    [key: string]: any;
}

export default class FormStructure {
    org: any;
    edit: any;
    structures: any;
    formRef: any;
    get key() {
        return 'form-' + (Math.random() * 1e10).toFixed(0) + '-' + Date.now();
    }
    public static NULL_TEXT: any = {
        NULL: null,
        UNDEFINED: undefined,
        STRING: ''
    };

    public static viewFormConfigToFormConfig(vfc: viewFormConfig[]): formStructureItem[] {
        return vfc
            .map((i) => {
                if (i.props) {
                    return i.props.flat().map((j: any) => ({
                        ...i,
                        key: i.key || j.key,
                        prop: j.prop || j,
                        props: undefined
                    }));
                } else {
                    return i;
                }
            })
            .flat();
    }
    constructor(list: Array<formStructureItem>) {
        this.org = list;
        let edit: any = {},
            structures: any = {};
        list.filter((i) => !!(i.prop || i.key)).forEach((i) => {
            let key: string = i.key || i.prop || '';
            edit[key] = i.value ?? i.default ?? '';

            structures[key] = {
                ...i,
                orgValue: i.value ?? '',
                prop: i.prop || i.key,
                get value() {
                    return edit[key];
                }
            };
        });
        this.edit = edit;
        this.structures = structures;
    }
    initData() {
        for (let i in this.edit) {
            this.edit[i] = this.structures[i]?.orgValue ?? '';
        }
    }
    getFormData(nullText: any) {
        console.log(this);
        let formData: any = new FsData(this.formRef);
        Object.keys(this.structures).forEach((i: any) => {
            let item = this.structures[i],
                val = item?.handle?.(item.value) ?? item.value;
            formData[item.prop] = typeof val === 'boolean' ? val : val ?? nullText;
        });
        return formData;
    }
    setData(data: { [x: string]: any }) {
        Object.keys(this.edit).forEach((i) => {
            if (data[i] !== undefined || data[this.structures[i]?.prop] !== undefined) {
                this.edit[i] = data[i] || data[this.structures[i].prop];
            }
        });
        return this;
    }

    setRef(ref: any) {
        ref && (this.formRef = ref);
        console.log(this);
    }
    public static nullHandle(obj: any, nullVal: any = [null, ''], toVal = undefined) {
        Object.keys(obj).forEach((i) => {
            if (nullVal.includes(obj[i])) {
                obj[i] = toVal;
            }
        });
        return obj;
    }
}

class FsData {
    [key: string]: any;
    constructor(ref: any) {
        this[`form_ref_${Math.ceil(Math.random() * 1e5)}`] = ref?.validate;
    }

    get validats() {
        return Object.keys(this)
            .filter((k) => /^form_ref_/.test(k))
            .map((i) => this[i]);
    }

    _nullHandle(nullVal: any = [null, ''], toVal = undefined) {
        Object.keys(this).forEach((i) => {
            if (this.hasOwnProperty(i) && nullVal.includes(this[i])) {
                this[i] = toVal;
            }
        });
        return this;
    }
}
