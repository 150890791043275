import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
	{
		url: 'manage/movie/manage/add',
		name: 'addVideoNew',
		method: 'post'
	},
	{
		url: 'manage/movie/manage/delete',
		name: 'delVideoNew',
		method: 'post'
	},
	{
		url: 'manage/movie/manage/details',
		name: 'getVideoNew',
		method: 'get'
	},
	{
		url: 'manage/movie/manage/list',
		name: 'getVideoNewList',
		method: 'post'
	},
	{
		url: 'manage/movie/manage/update',
		name: 'editVideoNew',
		method: 'post'
	},
	{
		url: 'manage/movie/manage/updateReleaseStatus',
		name: 'updateVideoStatus',
		method: 'post'
	}
];
export { list as commonConfig };
const common: Sqi = new Sqi(list);
export default common;
