<template>
    <el-cascader
        :options="brandList"
        :props="props"
        filterable
        :placeholder="[_brandName, _vipName].filter((i) => i).join('/')"
        v-model="val"
    ></el-cascader>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import ServerEnum from '@/store/modules/global';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    @Component({
        name: 'SelectBrandVip'
    })
    export default class SelectBrandVip extends SqiExtend {
        @Model('change') value: any;

        get val() {
            if (this.value) {
                return this.value;
            } else {
                if (this._brandId) {
                    if (this._vipId) {
                        return [this._brandId, this._vipId];
                    } else {
                        return [this._brandId];
                    }
                } else {
                    return [];
                }
            }
        }
        set val(n) {
            this.$emit('change', n);
            this._brandId = n[0] ?? '';
            this._vipId = n[1] ?? '';
        }

        @Watch('_brandId', { immediate: true })
        brandChange(n) {
            this._brandName = this.brandEnum[n] ?? '';
        }

        @Watch('_vipId', { immediate: true })
        vipChange(n) {
            if (this.vipEnum[n]) {
                this._vipName = this.vipEnum[n] ?? '';
            } else {
                this._brandId &&
                    this.$sqi.getVipByBrand({ brandId: this._brandId }).then(({ data }) => {
                        let vip = data.forEach((i) => {
                            if (i.id === n) {
                                this._vipName = i.cardName;
                            }
                        });
                    });
            }
        }

        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        @PropSync('brandId') _brandId: any;
        @PropSync('brandName') _brandName: any;
        @PropSync('vipId') _vipId: any;
        @PropSync('vipName') _vipName: any;

        vipList: any = [];

        get brandEnum() {
            return new EnumLabel(this.brandList).VALUE_LABEL;
        }
        get vipEnum() {
            return new EnumLabel(this.vipList).VALUE_LABEL;
        }

        get props() {
            return {
                lazy: true,
                lazyLoad: this.getVipByBrand
            };
        }

        get brandList() {
            return ServerEnum.brand;
        }

        getVipByBrand({ value }, resolve) {
            this.$sqi.getVipByBrand({ brandId: value }).then(({ data }) => {
                let vip = data.map((i) => ({
                    leaf: true,
                    label: i.cardName,
                    value: i.id
                }));
                this.vipList = this.vipList.concat(vip);
                resolve?.(vip);
            });
        }

        created() {
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
