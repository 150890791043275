<template>
    <el-button v-if="isEl" :type="type" v-bind="$props" plain @click="$emit('click', $event)">
        <slot></slot>
    </el-button>
    <button v-else-if="!isIcon" :class="'not-el ' + stylClass">
        <slot></slot>
    </button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '查看'"
        type="primary"
        icon="el-icon-view"
        plain
        v-bind="$props"
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '地址'"
        type="success"
        icon="el-icon-map-location"
        plain
        v-bind="$props"
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '编辑'"
        icon="el-icon-edit"
        plain
        v-bind="$props"
        type="warning"
        circle
    ></el-button>

    <el-popconfirm
        title="确定删除吗？"
        placement="top"
        v-else-if="$slots.default[0].text.trim() === '删除'"
        @confirm="$emit('click')"
    >
        <el-button
            slot="reference"
            icon="el-icon-delete"
            type="danger"
            v-bind="$props"
            plain
            circle
        ></el-button>
    </el-popconfirm>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '合同审核'"
        icon="el-icon-s-management"
        type="danger"
        plain
        v-bind="$props"
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '储值审核'"
        icon="el-icon-s-finance"
        type="danger"
        plain
        v-bind="$props"
        circle
    ></el-button>

    <!-- -->
    <el-popconfirm
        title="确定发送快递？"
        placement="top"
        v-else-if="$slots.default[0].text.trim() === '发送快递'"
        @confirm="$emit('click')"
    >
        <el-button
            slot="reference"
            icon="el-icon-shopping-cart-1"
            v-bind="$props"
            type="success"
            plain
            circle
        ></el-button>
    </el-popconfirm>

    <el-popconfirm
        title="确定关闭订单？"
        placement="top"
        v-else-if="$slots.default[0].text.trim() === '关闭订单'"
        @confirm="$emit('click')"
    >
        <el-button
            slot="reference"
            icon="el-icon-close"
            v-bind="$props"
            type="danger"
            plain
            circle
        ></el-button>
    </el-popconfirm>

    <el-button
        v-else-if="$slots.default[0].text.trim() === '上传'"
        icon="el-icon-upload"
        type="danger"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '刷新'"
        icon="el-icon-refresh"
        type="success"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '门店设置'"
        icon="el-icon-setting"
        type="primary"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '发送'"
        icon="el-icon-message"
        type="primary"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '隐藏'"
        icon="el-icon-turn-off-microphone"
        type="danger"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '导出'"
        icon="el-icon-download"
        type="primary"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '打印机'"
        icon="el-icon-printer"
        type="primary"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="['新增', '创建'].includes($slots.default[0].text.trim())"
        icon="el-icon-plus"
        type="primary"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === '密码'"
        icon="el-icon-lock"
        type="primary"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <el-button
        v-else-if="$slots.default[0].text.trim() === 'money'"
        icon="el-icon-money"
        type="primary"
        v-bind="$props"
        plain
        circle
    ></el-button>
    <button v-else :class="'not-el ' + stylClass">
        <slot></slot>
    </button>

    <!-- <button
		v-else
		:class="'not-el ' + stylClass"
	>
		<slot></slot>

	</button> -->
</template>
<script lang="tsx">
    import { Component, Inject, Model, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
    import { Button } from 'element-ui';
    import { mixins } from 'vue-class-component';

    const EL_TYPES: any = ['primary', 'success', 'warning', 'danger', 'info', 'text'];

    @Component({
        name: 'ViewButton',
        components: {}
    })
    export default class ViewButton extends mixins(Button) {
        @Prop({ type: Boolean, default: true }) isIcon?: boolean;
        @Prop({ type: String, default: '' }) icon?: boolean;
        get stylClass() {
            const { isEl } = this;
            if (!isEl) {
                return 'vb-' + this.type;
            } else {
                return '';
            }
        }
        // @Prop({default:true}) plain?: boolean;
        // plain: boolean = true;
        get isEl() {
            return EL_TYPES.includes(this.type);
        }
        created() {}

        // render(h) {
        // 	const { isEl, $slots, stylClass } = this;
        // 	return !isEl ? (
        // 		<button class={'not-el ' + stylClass}>{$slots.default[0]}</button>
        // 	) : (
        // 		Button.render.call(Object.assign(this, { plain: true }), h)
        // 	);
        // }
    }
</script>
<style lang="scss" scoped>
    button.not-el {
        &.vb-oper {
            border: none;
            display: inline-block;
            width: auto;
            text-decoration: underline;
            color: #0066ce;
            background-color: transparent;
            cursor: pointer;
        }
    }
    button + span {
        margin-left: 10px;
    }
</style>
