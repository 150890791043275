<template>
    <!-- v-model="shops" -->
    <el-cascader
        :options="brandsShop"
        v-model="shops"
        collapse-tags
        filterable
        @change="change"
        :props="customProps || { multiple: multiple, checkStrictly: !multiple }"
    ></el-cascader>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';

    /**禁止跨品牌选择门店 */
    @Component({
        name: 'SelectShops'
    })
    export default class SelectShops extends SqiExtend {
        @ModelSync('value', 'change') val: any;
        @PropSync('brand') brandId: any;
        @Prop({ default: false }) multiple?: boolean;
        @Prop() customProps?: any;
        vas: any = [];
        get shops() {
            // if (this.val && !this.multiple) {
            //     return this.val;
            // }
            if (this.multiple) {
                let shops = this.val ?? [],
                    brand = this.brandId;
                if (shops.length > 0 && brand) {
                    return shops.map((i) => [brand, i]);
                } else {
                    return [];
                }
            } else {
                return this.val ? [this.brandId, this.val] : [this.brandId];
            }
        }

        // shops1: any = [];

        set shops(n) {
            if (typeof n === 'number') {
                this.val = n;
            }
            if (this.multiple) {
                this.val = n.map((i) => i[1]);
                this.brandId = n?.[0]?.[0] || '';
            } else {
                this.val = n[1] ?? '';
                this.brandId = n[0] ?? '';
            }
        }
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        currentBrand: number = 0;
        change(e) {
            const { currentBrand } = this;
            if (this.multiple) {
                let shops = e?.filter?.((i) => i[0] !== currentBrand);
                if (shops?.[0]) {
                    this.shops = shops;
                    this.currentBrand = shops[0][0];
                }
            } else {
                this.shops = e;
            }
        }

        get shopOrg() {
            if (!this.multiple) {
                let brand = this.brandsShop.filter((i) => i.value === this.brandId)?.[0],
                    shop = brand?.children.filter((i) => i.value === this.val)?.[0];
                return { brand, shop };
            }
        }

        @Watch('shopOrg')
        emitChange() {
            this.$emit('org-change', this.shopOrg);
        }
        brandsShop: any = [];
        getBrandList() {
            this.$sqi.getAuthorizedBrandShops().then(({ data: brandsShop }) => {
                this.brandsShop = brandsShop;
            });
        }

        created() {
            this.getBrandList();
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
