<template>
    <el-select v-model="val" filterable>
        <el-option v-for="item in cardList" :key="item.value" v-bind="item"> </el-option>
    </el-select>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync,
        PropSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    @Component({
        name: 'SelectCard'
    })
    export default class SelectCard extends SqiExtend {
        @ModelSync('value', 'change') val: any;
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        @PropSync('id') _id: any;
        @PropSync('name') _name: any;
        @Prop({ type: [String, Number], required: true }) brand: any;
        cardList: any = [];

        listEnum: any = [];
        @Watch('val')
        valChange(n) {
            this._id = n;
        }

        @Watch('val')
        @Watch('listEnum')
        setName() {
            this._name = this.listEnum?.VALUE_LABEL?.[this._id] ?? '';
        }

        @Watch('_id', { immediate: true })
        idChange(n) {
            this.val = n;
        }
        @Watch('brand', { immediate: true })
        getCardList(n) {
            n &&
                this.$sqi.getAllVipCards({ brandId: n }).then(({ data: card }) => {
                    this.cardList = card;
                    this.listEnum = card?.length ? new EnumLabel(card) : {};
                });
        }

        created() {
            // this.getCardList();
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
