<template>
    <div class="view-search" :class="formList.length >= 4 && 'vs-center'">
        <!-- <el-form inline>
			<el-form-item
				v-for="(i,k) of formList"
				:key="k"
				:label="i.label"
				:class="i.type === FORM_ITEM_TYPE.TEXTAREA && 'form-item-textarea'"
			>
				<template v-if="i.site">
					<slot
						:name="i.site"
						:edit="edit"
					/>
				</template>
				<template v-else-if="i.type === FORM_ITEM_TYPE.SHOW">
					{{i.value}}
				</template>
				<template v-else-if="i.type === FORM_ITEM_TYPE.SELECT">
					<el-select
						v-model="edit[i.prop||i.value]"
						v-if="i.options"
						v-bind="i.select"
					>
						<el-option
							v-for="item in i.options"
							:key="item.value"
							v-bind="item"
						>
						</el-option>
					</el-select>
				</template>
				<template v-else-if="i.type === FORM_ITEM_TYPE.DATE">
					<el-date-picker
						v-model="edit[i.prop||i.value]"
						type="date"
						value-format="yyyy-MM-dd"
						:placeholder="i.placeholder||'选择日期'"
					>
					</el-date-picker>
				</template>
				<template v-else-if="i.type === FORM_ITEM_TYPE.DATE_RANGE">
					<el-date-picker
						v-model="edit[i.prop||i.value]"
						type="daterange"
						value-format="yyyy-MM-dd"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</template>
				<template v-else-if="i.type === FORM_ITEM_TYPE.DATE_RANGE_DOUBLE">
					<date-range-double
						:stime.sync="edit[i.props[0]]"
						:etime.sync="edit[i.props[1]]"
					>
					</date-range-double>
				</template>
				<template v-else-if="i.type === FORM_ITEM_TYPE.SELECT_SHOP">
					<select-shop
						v-model="edit[i.prop||i.props[1]||'']"
						:brand.sync="edit[(i.props&&i.props[0])||(i.type+k+'-brand')]"
						v-bind="i"
					>
					</select-shop>
				</template>
				<template v-else-if="i.type === FORM_ITEM_TYPE.BRAND">
					<el-select
						v-model="edit[i.prop||i.value]"
						v-bind="i.select"
					>
						<el-option
							v-for="item in brandList"
							:key="item.value"
							v-bind="item"
						>
						</el-option>
					</el-select>
				</template>
				<template v-else>
					<el-input
						:type="i.type"
						v-model="edit[i.prop||i.value]"
					></el-input>
				</template>
			</el-form-item>

		</el-form> -->
        <view-form v-bind="$props" v-model="form" :inline="true" is-search></view-form>
        <v-button @click="search" v-if="formList.length" type="primary">查询</v-button>
        <v-button @click="clear" v-if="formList.length" type="info">重置</v-button>
        <slot></slot>
    </div>
</template>
<script lang="ts">
    import { Component, Inject, Model, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import DateRangeDouble from '@/components/DateRangeDouble';
    import SelectShop from '@/components/SelectShop';
    import SqiExtend from '@/api';
    import ViewForm from '../ViewForm';
    import ViewButton from '../ViewButton';

    @Component({
        name: 'ViewSearch',
        components: { DateRangeDouble, SelectShop, ViewForm, VButton: ViewButton }
    })
    export default class ViewSearch extends SqiExtend {
        @Prop() formList: any;
        @Prop() api: any;
        @Prop() init: any;
        @Prop({ default: false, type: Boolean }) isDoubleRow?: boolean;
        @Prop({ type: Function }) dataHandle?: any;
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;
        form: any = {};
        // get types() {
        // 	return this.formList.map((i: any) => i.type);
        // }

        @Watch('formList', { immediate: true })
        setForm() {
            // let formList = this.formList.filter((i: any) => i.prop);
            // this.form = new FormStructure([...formList, ...props]);
            // this.edit = this.form.edit;
            // this.init && this.form.setData(this.init);
            // if (this.types.includes(FORM_ITEM_TYPE.BRAND)) {
            // 	this.getBrandList();
            // }
        }

        search() {
            this.$emit(
                'search',
                this.form.getFormData(FormStructure.NULL_TEXT.UNDEFINED)._nullHandle()
            );
        }
        clear() {
            this.form.initData();
            this.$emit(
                'search',
                this.form.getFormData(FormStructure.NULL_TEXT.UNDEFINED)._nullHandle()
            );
        }
        searchInit = {};

        created() {
            let search = this.$route.query.search;
            // search && (this.searchInit = JSON.stringify(search));
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped>
    .view-search {
        padding: 15px 0;
        &.vs-center {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            align-content: flex-start;
        }
        &::v-deep {
        }
    }
</style>
