import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/gzh/menu/add',
        method: 'post',
        name: 'addGzhMenu'
    },
    {
        url: 'manage/gzh/menu/delete',
        method: 'post',
        name: 'delGzhMenu'
    },
    {
        url: 'manage/gzh/menu/detail',
        method: 'get',
        name: 'getGzhMenuDetail'
    },
    {
        url: 'manage/gzh/menu/list',
        method: 'get',
        name: 'getGzhMenuList'
    },
    {
        url: 'manage/gzh/menu/refreshMenu',
        method: 'post',
        name: 'refreshGzhMenu'
    },
    {
        url: 'manage/gzh/menu/removeMenu',
        method: 'post',
        name: 'removeGzhMenu'
    },
    {
        url: 'manage/gzh/menu/tree',
        method: 'post',
        name: 'getGzhMenuTree'
    },
    {
        url: 'manage/gzh/menu/update',
        method: 'post',
        name: 'editGzhMenu'
    },

    {
        url: 'manage/manage/KangJiaDevice/add',
        method: 'post',
        name: 'addKjDev'
    },

    {
        url: 'manage/manage/KangJiaDevice/delete',
        method: 'post',
        name: 'delKjDev'
    },

    {
        url: 'manage/manage/KangJiaDevice/detail',
        method: 'get',
        name: 'getKjDev'
    },

    {
        url: 'manage/manage/KangJiaDevice/list',
        method: 'get',
        name: 'getKjDevList'
    },
    {
        url: 'manage/manage/KangJiaDevice/update',
        method: 'post',
        name: 'editKjDev'
    },

    {
        url: 'manage/gzhRobot/manage/add',
        method: 'post',
        name: 'addGzhRobot'
    },
    {
        url: 'manage/gzhRobot/manage/addMaterial',
        method: 'post',
        name: 'addGzhWxMedia'
    },
    {
        url: 'manage/gzhRobot/manage/del',
        method: 'post',
        name: 'delGzhRobot'
    },
    {
        url: 'manage/gzhRobot/manage/getSendRecordInfo',
        method: 'post',
        name: 'getGzhRobotRecord'
    },
    {
        url: 'manage/gzhRobot/manage/getSendRecordList',
        method: 'post',
        name: 'getGzhRobotRecordList'
    },
    {
        url: 'manage/gzhRobot/manage/info',
        method: 'get',
        name: 'getGzhRobot'
    },
    {
        url: 'manage/gzhRobot/manage/list',
        method: 'post',
        name: 'getGzhRobotList'
    },
    {
        url: 'manage/gzhRobot/manage/update',
        method: 'post',
        name: 'editGzhRobot'
    },
    {
        url: 'manage/gzhRobot/manage/updateStatus',
        method: 'post',
        name: 'setGzhRobotStatus'
    },

    {
        url: 'manage/gzhAutoSend/manage/add',
        method: 'post',
        name: 'addGzhMessageTmp'
    },

    {
        url: 'manage/gzhAutoSend/manage/startJob',
        method: 'post',
        name: 'startGzhMessageTmp'
    },
    {
        url: 'manage/gzhAutoSend/manage/addMember',
        method: 'post',
        name: 'addGzhMessageTmpMember'
    },
    {
        url: 'manage/gzhAutoSend/manage/del',
        method: 'post',
        name: 'delGzhMessageTmp'
    },
    {
        url: 'manage/gzhAutoSend/manage/delMembers',
        method: 'post',
        name: 'delGzhMessageTmpMember'
    },
    {
        url: 'manage/gzhAutoSend/manage/importMember',
        method: 'post',
        name: 'importGzhMessageTmpMember'
    },
    {
        url: 'manage/gzhAutoSend/manage/list',
        method: 'post',
        name: 'getGzhMessageTmpList'
    },
    {
        url: 'manage/gzhAutoSend/manage/update',
        method: 'post',
        name: 'editGzhMessageTmp'
    },
    {
        url: 'manage/gzhAutoSend/manage/updateStatus',
        method: 'post',
        name: 'setGzhMessageTmpStatus'
    },
    {
        url: 'manage/gzhAutoSend/manage/getMembers',
        method: 'get',
        name: 'getGzhMessageMemberList'
    },

    {
        url: '/manage/gzhTemplate/manage/add',
        method: 'post',
        name: 'addGzhTmp'
    },
    {
        url: '/manage/gzhTemplate/manage/list',
        method: 'post',
        name: 'getGzhTmpList'
    },
    {
        url: '/manage/gzhTemplate/manage/update',
        method: 'post',
        name: 'editGzhTmp'
    },
    {
        url: '/manage/gzhTemplate/manage/updateStatus',
        method: 'post',
        name: 'setGzhTmpStatus'
    },
    {
        url: '/manage/gzhTemplate/manage/lockGzhTemplate',
        method: 'post',
        name: 'setGzhTmpLock'
    },
    {
        url: '/manage/gzhTemplate/manage/del',
        method: 'post',
        name: 'delGzhTmp'
    },
    {
        url: '/manage/gzhTemplate/manage/getRecordList',
        method: 'post',
        name: 'getGzhTmpRecord'
    },
    {
        url: '/manage/gzhTemplate/manage/info',
        method: 'get',
        name: 'getGzhTmp'
    },

    {
        url: '/manage/fixedTemplate/manage/add',
        method: 'post',
        name: 'addWxMsgTmp'
    },
    {
        url: '/manage/fixedTemplate/manage/getFixedTemplateErrRecord',
        method: 'post',
        name: 'getWxMsgTmpRecord'
    },
    {
        url: '/manage/fixedTemplate/manage/getFixedTemplateMegType',
        method: 'get',
        name: 'getFixedTemplateMegType'
    },
    {
        url: '/manage/fixedTemplate/manage/info',
        method: 'get',
        name: 'getWxMsgTmp'
    },
    {
        url: '/manage/fixedTemplate/manage/list',
        method: 'post',
        name: 'getWxMsgTmpList'
    },
    {
        url: '/manage/fixedTemplate/manage/update',
        method: 'post',
        name: 'editWxMsgTmp'
    }
];
const activity: Sqi = new Sqi(list);
export default activity;
