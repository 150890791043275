import router from '@/router/index';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Message } from 'element-ui';
import { Route } from 'vue-router';
import { UserModule } from '@/store/modules/user';
import Global from '@/store/modules/global';

NProgress.configure({ showSpinner: false });

const whiteList = ['/login'];

router.beforeEach(async (to: Route, _: Route, next: any) => {
    // Start progress bar
    console.log(to, '-------1--------');
    if (/^\/access\_token/.test(to.path)) {
        next(to.path.replace('/', '/?'));
        NProgress.done();
    }
    NProgress.start();
    if (whiteList.includes(to.path)) {
        // If is logged in, redirect to the home page
        next();
        NProgress.done();
    } else {
        try {
            if (!Global.isGet) {
                await Global.GET_GLOBAL();
            }
            if (!UserModule.id) {
                await UserModule.GetUserInfo();
                next({ ...to, replace: true });
            } else {
                next();
            }
            // await UserModule.GetUserInfo()
            // Set the replace: true, so the navigation will not leave a history record
            // NProgress.done()
        } catch (e) {
            console.log(e);
            // Message.error(e || 'Has Error');
            next(`/login?redirect=${to.path}`);
            NProgress.done();
        }
    }

    // Determine whether the user has logged in
    // if (UserModule.token) {
    //   if (to.path === '/login') {
    //     // If is logged in, redirect to the home page
    //     next({ path: '/' })
    //     NProgress.done()
    //   } else {
    //     // Check whether the user has obtained his permission roles
    //     if (UserModule.roles.length === 0) {
    //       try {
    //         // Get user info, including roles
    //         await UserModule.GetUserInfo()
    //         // Set the replace: true, so the navigation will not leave a history record
    //         next({ ...to, replace: true })
    //       } catch (err) {
    //         // Remove token and redirect to login page
    //         UserModule.ResetToken()
    //         Message.error(err || 'Has Error')
    //         next(`/login?redirect=${to.path}`)
    //         NProgress.done()
    //       }
    //     } else {
    //       next()
    //     }
    //   }
    // } else {
    //   // Has no token
    //   if (whiteList.indexOf(to.path) !== -1) {
    //     // In the free login whitelist, go directly
    //     next()
    //   } else {
    //     // Other pages that do not have permission to access are redirected to the login page.
    //     next(`/login?redirect=${to.path}`)
    //     NProgress.done()
    //   }
    // }
});

router.afterEach((to: Route) => {
    // Finish progress bar
    NProgress.done();

    // set page title
    document.title = `电小二客户管理系统 - ${to.meta.title}`;
});
