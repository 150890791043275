<template>
    <!--分页组件-->
    <div class="view-pagination">
        <slot />
        <el-pagination
            v-bind="$props"
            :current-page="currentPage"
            :layout="layout"
            :page-size="pageSize"
            background
            :hide-on-single-page="hideOnSinglePage"
            :page-sizes="pageSizes"
            @size-change="sizeChange"
            @current-change="indexChange"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Inject, Model, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DEFAULT_SIZE } from '@/constant/global';

    @Component({
        name: 'ViewPagination'
    })
    export default class ViewPagination extends Vue {
        @Prop({ type: Number }) total: any;
        @Prop() currentPage;
        @Prop({ default: false, type: Boolean }) hideOnSinglePage: any;
        @Prop({ type: String, default: 'total, sizes, prev, pager, next, jumper' })
        layout;
        // 默认显示条数
        @Prop({
            type: Array,
            default() {
                return [DEFAULT_SIZE, 20, 50, 100];
            }
        })
        pageSizes;

        @Prop() pageSize;
        sizeChange(size) {
            this.$emit('size-change', size);
            this.$emit('update:pageSize', size);
        }

        indexChange(index) {
            this.$emit('update:currentPage', index);
            this.$emit('current-change', index);
        }
    }
</script>

<style lang="scss">
    // $paginationColor:#223750;
    // .el-pager li.active{
    //     background: $paginationColor !important;
    //     color:#fff !important;
    // }
    // .el-pager li{
    //     border-radius: 4px;
    //     color:#666;
    //     border: 1px solid #eee;
    //     width: 44px;
    //     height: 32px;
    //     line-height: 32px;
    //     margin-right: 6px;
    //     &:hover{
    //         background: $paginationColor !important;
    //         color:#fff !important;
    //     }
    // }
    .view-pagination {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 25px;
        // flex-direction: column-reverse;
        // align-content:flex-end;
    }
    .el-pagination {
        flex-grow: 1;
        text-align: right;
        // padding: 2px 30px 30px;
    }
</style>
