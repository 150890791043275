import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { login, logout, getUserInfo, getMenuList, getUserRole } from '@/api/users';
import { addRoutes } from '@/router/index';
import store from '@/store';

export interface IUserState {
    name: string;
    id: string;
    avatar: string;
    introduction: string;
    roles: string[];
    permissions: any[];
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
    public id = '';
    public name = '';
    public avatar = '';
    public introduction = '';
    public roles: string[] = [];
    public permissions: any = [];

    @Mutation
    private SET_ID(id: string) {
        this.id = id;
    }

    @Mutation
    private SET_NAME(name: string) {
        this.name = name;
    }

    @Mutation
    private SET_AVATAR(avatar: string) {
        this.avatar = avatar;
    }

    @Mutation
    private SET_INTRODUCTION(introduction: string) {
        this.introduction = introduction;
    }

    @Mutation
    private SET_ROLES(roles: string[]) {
        this.roles = roles;
    }
    @Mutation
    private SET_PERMISSIONS(roles: string[]) {
        this.permissions = roles;
    }

    @Action
    public async Login(userInfo: { username: string; password: string; captchaCode: string }) {
        let { username, password, captchaCode } = userInfo;
        username = username.trim();
        const response = await login({ username, password, captchaCode });
        // setToken(data.accessToken)
        // this.SET_TOKEN(data.accessToken)
    }

    @Action
    public async GetUserInfo() {
        try {
            const { data } = await getUserInfo({
                /* Your params here */
            });
            addRoutes();
            // const { data: roles } = await getMenuList();
            // const { data: per } = await getUserRole();
            const { id, name } = data;
            // addRoutes(roles);
            this.SET_ID(id);
            this.SET_NAME(name);
            // this.SET_ROLES(roles);
            // this.SET_PERMISSIONS(per);
        } catch (e) {
            throw e;
        }
    }

    @Action
    public async LogOut() {
        await logout();
        this.SET_ROLES([]);
        this.SET_ID('');
        this.SET_NAME('');
        location.href = '#/login';
    }
}

export const UserModule = getModule(User);
