import Vue from 'vue';
import Global from '@/store/modules/global';
const filters: any = {
    formatPrice(m) {
        return parseFloat(m).toFixed(2);
    },

    formatDate(timestamp) {
        return new Date(parseInt(timestamp) * 1e3).formatDate();
    },

    datetime(time: string) {
        return time;
    },
    formatDateTime(timestamp) {
        return new Date(parseInt(timestamp) * 1e3).format();
    },
    timestringFormat(timestring: string) {
        return timestring.split(' ')[0];
    },
    contractStatus(e: number) {
        return Global.gobaleEnum.CONTRACT_STATUS.VALUE_LABEL[e] || '--';
    },
    userStatus(e: number, row) {
        let ut = Global.gobaleEnum.USER_TYPE.PROP_VALUE;
        if (row.type == ut.NORMAL) {
            return '--';
        } else if (row.type == ut.STORE) {
            return Global.gobaleEnum.STORE_STATUS.VALUE_LABEL[row.regStatus];
        } else if (row.type == ut.CONTRACT) {
            return Global.gobaleEnum.CONTRACT_STATUS.VALUE_LABEL[row.contractStatus];
        }
        return '--';
    },
    commonStatus(i: any) {
        return Global.gobaleEnum.COMMON_STATUS.VALUE_LABEL[i];
    },
    userType(i: any) {
        return Global.gobaleEnum.USER_TYPE.VALUE_LABEL[i];
    },
    payType(i: any) {
        return Global.gobaleEnum.PAY_TYPE.VALUE_LABEL[i];
    },
    orderStatus(i: any) {
        return Global.gobaleEnum.ORDER_STATUS.VALUE_LABEL[i];
    },
    shopType(i: any) {
        return Global.gobaleEnum.SHOP_TYPE.VALUE_LABEL[i];
    }
};

for (const i in filters) {
    filters.hasOwnProperty(i) && Vue.filter(i, filters[i]);
}
export default filters;
