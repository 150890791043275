<template>
    <el-select v-model="val" multiple collapse-tags>
        <el-option v-for="item in brandList" :key="item.value" v-bind="item"> </el-option>
    </el-select>
</template>
<script lang="ts">
    import {
        Component,
        Inject,
        Model,
        Prop,
        Vue,
        Watch,
        Emit,
        ModelSync
    } from 'vue-property-decorator';
    import { FORM_ITEM_TYPE } from '@/constant/global';
    import FormStructure from '@/utils/FormStructure';
    import SqiExtend from '@/api';
    import { mixins } from 'vue-class-component';
    import EnumLabel from '@/constant/EnumLabel';
    import Global from '@/store/modules/global';
    @Component({
        name: 'SelectRoom'
    })
    export default class SelectRoom extends SqiExtend {
        @ModelSync('value', 'change') val: any;
        FORM_ITEM_TYPE: any = FORM_ITEM_TYPE;

        get brandList() {
            return EnumLabel.serverToEnum(Global.global.ROOM_TYPE).org;
        }
        // getRoomList() {
        // 	this.$sqi.getAllRoomTypes().then(({ data: room }) => {
        // 		this.brandList = room;
        // 	});
        // }

        created() {
            // this.setForm();
        }
    }
</script>
<style lang="scss" scoped></style>
