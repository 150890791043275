import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/coupon/manage/list',
        name: 'getCouponList',
        method: 'get'
    },
    {
        url: '/manage/coupon/manage/detail',
        name: 'getCouponDetails',
        method: 'get'
    },
    {
        url: 'manage/coupon/manage/add',
        name: 'addCoupon',
        method: 'post'
    },
    {
        url: 'manage/coupon/manage/update',
        name: 'editCoupon',
        method: 'post'
    },
    {
        url: '/manage/campaign/manage/list',
        name: 'getCampaignList',
        method: 'post'
    },
    {
        url: '/manage/campaign/manage/update',
        name: 'updateCampaign',
        method: 'post'
    },
    {
        url: '/manage/campaign/manage/getCampaignInfo',
        method: 'get'
    },
    {
        url: '/manage/campaign/manage/getCampaignScope',
        method: 'get'
    },
    {
        url: '/manage/campaign/manage/updatePopStatus',
        method: 'post',
        name: 'updateCampaignPopStatus'
    },
    {
        url: '/manage/campaign/manage/getJoinMember',
        method: 'get'
    },
    {
        url: '/manage/campaign/manage/updateStatus',
        method: 'post',
        name: 'updateCampaignStatus'
    },
    {
        url: '/manage/campaign/manage/delete',
        name: 'deleteCampaign',
        method: 'post'
    },
    {
        url: '/manage/campaign/manage/add',
        name: 'addCampaign',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/list',
        name: 'getAutoMarketList',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/startMarkting',
        name: 'sendAutoMarket',
        method: 'get'
    },
    {
        url: '/manage/autoMarketing/manage/failRecord',
        name: 'getSendErrorMember',
        method: 'get'
    },
    {
        url: '/manage/autoMarketing/manage/failRecordList',
        name: 'getSendErroRecordList',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/getLiable',
        name: 'getAutoMenu',
        method: 'get'
    },
    {
        url: '/manage/autoMarketing/manage/resend',
        name: 'resendAutoMarketMessage',
        method: 'post'
    },

    {
        url: '/manage/autoMarketing/manage/update',
        name: 'editAutoMarket',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/add',
        name: 'addAutoMarket',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/info',
        name: 'getAutoMarket',
        method: 'get'
    },
    {
        url: '/manage/autoMarketing/manage/filterCrowd',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/delete',
        name: 'delAutoMarket',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/updateStatus',
        name: 'updateAutoMarketStatus',
        method: 'post'
    },
    {
        url: '/manage/autoMarketing/manage/getAutoMarketingPerson',
        method: 'get'
    },
    {
        url: '/manage/autoMarketing/manage/getBlackLists',
        method: 'get'
    },
    {
        url: '/manage/pointGoods/manage/add',
        method: 'post',
        name: 'addPointGoods'
    },
    {
        url: '/manage/pointGoods/manage/deleteShopPointGoods',
        method: 'post',
        name: 'delPointGoodsShop'
    },
    {
        url: '/manage/pointGoods/manage/detail',
        method: 'get',
        name: 'getPointGood'
    },
    {
        url: '/manage/pointGoods/manage/list',
        method: 'get',
        name: 'getPointGoodsList'
    },
    {
        url: '/manage/pointGoods/manage/delete',
        method: 'post',
        name: 'delPointGoods'
    },
    {
        url: '/manage/pointGoods/manage/queryPointGoodsShopIds',
        method: 'get',
        name: 'getPointGoodsByShop'
    },
    {
        url: '/manage/pointGoods/manage/queryShopSubject',
        method: 'get',
        name: 'getShopPointGood'
    },
    {
        url: '/manage/pointGoods/manage/queryShopSubjects',
        method: 'get',
        name: 'getShopPointGoodList'
    },
    {
        url: '/manage/pointGoods/manage/tree',
        method: 'get',
        name: 'getPointGoodsTree'
    },
    {
        url: '/manage/pointGoods/manage/update',
        method: 'post',
        name: 'editPointGoods'
    },
    {
        url: '/manage/pointGoods/manage/updateShopPointGoods',
        method: 'post',
        name: 'editPointGoodByShop'
    },
    {
        url: '/manage/pointGoods/manage/updateShopPointGoodsStatus',
        method: 'post',
        name: 'editPointGoodShopStatus'
    },
    {
        url: '/manage/pointGoods/manage/updateStatus',
        method: 'post',
        name: 'editPointGoodStatus'
    },
    {
        url: '/manage/pointGoods/manage/categoryTree',
        method: 'get',
        name: 'getPointGoodTypeTree'
    },
    {
        url: '/manage/pointGoods/manage/copy',
        method: 'post',
        name: 'copyPointGood'
    },
    {
        url: '/manage/pointGoods/record/list',
        method: 'post',
        name: 'getPointRecordList'
    },
    {
        url: '/manage/campaign/manage/getQrCode',
        method: 'get',
        name: 'getCampaignQrcode'
    },

    {
        url: '/manage/automatic/manage/add',
        method: 'post',
        name: 'addGiveCoupon'
    },
    {
        url: '/manage/automatic/manage/delete',
        method: 'post',
        name: 'delGiveCoupon'
    },
    {
        url: '/manage/automatic/manage/detail',
        method: 'get',
        name: 'getGiveCoupon'
    },
    {
        url: '/manage/automatic/manage/list',
        method: 'get',
        name: 'getGiveCouponList'
    },
    {
        url: '/manage/automatic/manage/repairProvide',
        method: 'post',
        name: 'repairGiveCoupon'
    },
    {
        url: '/manage/automatic/manage/update',
        method: 'post',
        name: 'editGiveCoupon'
    },
    {
        url: '/manage/automatic/manage/updateStatus',
        method: 'post',
        name: 'editGiveCouponStatus'
    },
    {
        url: '/manage/automatic/manage/getMemberCount',
        method: 'get',
        name: 'getGiveMemberCount'
    }
];
const activity: Sqi = new Sqi(list);
export default activity;
