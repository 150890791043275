import { Sqi, SqiItemConfig } from './Sqi';

const list: SqiItemConfig[] = [
    {
        url: 'manage/vipCard/manage/list',
        method: 'get',
        name: 'getDiscountCardUserList'
    },
    {
        url: 'manage/vipCard/manage/delete',
        method: 'post',
        name: 'delVipCard'
    },
    {
        url: 'manage/vipCard/manage/orderList',
        method: 'get',
        name: 'getDiscountCardOrderList'
    },
    {
        url: 'manage/vipCard/activity/list',
        method: 'get',
        name: 'getDiscountCardActivityList'
    },
    {
        url: 'manage/vipCard/activity/detail',
        method: 'get',
        name: 'getDiscountCardActivityDetails'
    },
    {
        url: 'manage/vipCard/activity/add',
        method: 'post',
        name: 'addDiscountCardActivit'
    },
    {
        url: 'manage/vipCard/activity/update',
        method: 'post',
        name: 'updateDiscountCardActivit'
    },
    {
        url: 'manage/vipCard/manage/receiveEntityCard',
        name: 'bindCard',
        method: 'post'
    },
    {
        url: 'manage/vipCard/activity/orderList',
        name: 'getVipCardActOrderList',
        method: 'get'
    }
];

const discountCard: Sqi = new Sqi(list);
export default discountCard;
