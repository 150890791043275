import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';

import store from '@/store';
import $sqi from '@/api/dict';
import axios from 'axios';
import EnumLabel from '@/constant/EnumLabel';
export interface IGlobalState {
    isGet: boolean;
    global: any;
}

@Module({ dynamic: true, store, name: 'ServerEnum' })
class ServerEnum extends VuexModule {
    public global: any = {};
    @Mutation
    SET_GLOBAL(dict: any) {
        const { data } = dict;

        this.global = data;
    }
    @Mutation
    SET_ORG(org: any[]) {
        this.org = org;
    }

    @Action
    public async GET_GLOBAL() {
        //全局服务器动态常量注入
        const dict = await $sqi.getDict();

        this.SET_GLOBAL(dict);
    }

    get gobaleEnum() {
        let gobal = this.global,
            enums: any = {};
        for (let i in gobal) {
            if (gobal[i]) {
                let s = i.replace(/\B([A-Z])/g, '_$1').toUpperCase();
                enums[s] = new EnumLabel(gobal[i]);
            }
        }
        return enums;
    }

    get isGet() {
        return !!Object.keys(this.global).length;
    }
}

export default getModule(ServerEnum);
